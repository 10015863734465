<template>
  <v-card
    outlined
    width="100%"
    class="mx-auto card-rounded over-hidden"
  >
    <v-row class="pa-3"> 
      <v-col cols="10" sm="10" md="10">
        <v-card-title class="pa-1 my-0 text-size-2">
          {{ oPunto.sNombrePunto }}
        </v-card-title>
        <v-row no-gutters>
         <v-col no-gutters>
           <v-card-subtitle class="pa-1 my-1 text-size-1">
             <span class="black--text">Estado</span>: 
             <v-chip label :color="onGetColorEstado(oPunto)" class="white--text" >
                {{ oPunto.sEstado }}
              </v-chip>
           </v-card-subtitle>
           <v-card-subtitle class="pa-1 my-1 text-size-1">
             <span class="black--text">Tipo:</span>
             {{ oPunto.sTipoMarcacion}}
            </v-card-subtitle>
         </v-col>
       </v-row>
      </v-col>

      <v-col cols="2" sm="2" md="1" class="d-flex justify-center align-center text-xs-center">
        <v-btn large icon @click="toggleDetalle" v-if="bShowBtnDetalle">
          <v-icon large class="icon-circle" color="#003743">{{ bShowDetalle ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-col>
      
    </v-row>
    <v-row width="100%">
      <slot name="informacion" v-bind:bShowDetalle="bShowDetalle"></slot>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'PuntoItemCard',
  props: {
    oPunto: {
      type: Object,
      default:()=> {}
    },
    bShowBtnDetalle:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      bShowDetalle:false
    };
  },
  computed: {

  },
  mounted() {
    
  },
  methods: {
    toggleDetalle() {
      this.bShowDetalle = !this.bShowDetalle;
    },
    onGetColorEstado(oPunto){
      switch (oPunto.sEstado) {
        case 'Sin marcar':
          return 'red accent-2'
        case 'Marcado':
          return '#019578'  
        default:
          return 'grey'
      }
    }
  },
};
</script>

<style scoped>
  .icon-circle {
    border-radius: 50%;
    background-color: #F8F8F8; 
    padding: 6px; 
    font-size: 18px; 
  }

</style>
