<template>
   <v-stepper v-model="nPaso" flat tile>
    <v-stepper-header class="stepper_header--app">
        <v-stepper-step v-for="(item, index) in aPasos"
          :key="index"        
          :complete="nPaso > item.id"
          :step="item.sId"
          class="paso-app"
          @click="onClickSteper(item.sId)"
        >
          {{ item.sTitulo}}
        </v-stepper-step>

      <!-- </div> -->
      <v-divider v-if="aPasos.length <= (aPasos.length-1)"></v-divider>
    </v-stepper-header>

    <v-stepper-items>
      <!-- Este componente tienen que invocarse siempre con el item  -->
     <slot ></slot>
    </v-stepper-items>
  </v-stepper>
</template>

<script>

export default {
  name: 'Pasos',
  components: {  },
  props: {
      aPasos: {
        type: Array,
        default: ()=> []
      },
      value: {
        type: Number,
        default: 1
      },
      onClickSteper: {
        type: Function
      }
    },
  data () {
		return {
      nPaso: 1
    }
	},
	mounted() {
   this.nPaso = this.value
  },
  methods: {
    
  },
	watch: {
    nPaso(NewValor){
			this.$emit('input',NewValor)
		},
    value(NewValor){
			this.nPaso = NewValor
		},
	},

}
</script>
<style lang="scss" scoped>
.paso-app{
  padding: 2px 16px!important;
  margin: 0 40px;
}
.stepper_header--app{
  background: #EEF6FE!important;
  box-shadow: none!important;
  height: 40px!important;
}
</style>