const state = () => ({
  mensajeNotificacion : "",
  snackbar : false, 

})

const getters = {
  getmensajeNotificacion: ( state ) => {
    return state.mensajeNotificacion;
  },
  getsnackbar: (state) => {
    return state.snackbar;
  },
} 

const mutations = {
  MENSAJE : ( state, estado ) => {
    state.mensajeNotificacion = estado;
  },
  NOTIFICAR : ( state, estado ) => {
    state.snackbar = estado;
  },
}

const actions = {
  SetMensaje( {commit}, estado ) {
    commit( "MENSAJE", estado );
  },
  ShowNotificacion( {commit}, estado ) {
    commit("NOTIFICAR", estado);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}