<template>
<ContenedorCampos :titulo="''" :isObligatorio="'false'">
  <div class="container my-5 class_info">
    <span my-10>{{titulo}}</span>
  </div>  
</ContenedorCampos>
</template>
<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'

export default {
  name: 'Informacion',
  mixins: [ DinamicosMixin ],
  components: {
    ContenedorCampos,
  },
  data () {
    return {

    }
  },
  mounted () {
  },
  methods: {

  },
  computed: {

  },

}
</script>

<style lang="scss" scoped>
 .class_info {
   border: solid 3px grey;
   border-radius: 5px;
 }
</style>
