<template>
  <v-row no-gutters justify="center">
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" v-if="!Object.prototype.hasOwnProperty.call( this.oInfoVisitante, 'FotoNueva' )" class="text-center">
      <span class="text-size-1">{{ $STR.stlInfoVisitante }}</span>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="pa-1">
      <v-card class="mx-auto card-rounded card-shadown" elevation="0">
        <v-card-text class="pa-0">
          <v-list-item v-if="!Object.prototype.hasOwnProperty.call( this.oInfoVisitante, 'FotoNueva' )" class="d-flex justify-center">
            <v-avatar size="150px" >
              <v-img
                :src="oInfoVisitante.FotoPersona"
                max-height="150px"
                max-width="150px"
              />
            </v-avatar>
          </v-list-item>
          <v-list-item class="d-flex justify-center">
            <fotos
              v-model="oInfoVisitante.FotoNueva"
              :sTitulo="''"
              :nCantidadFotos="1"
            />
          </v-list-item>
        </v-card-text>
        <!-- <v-card-title class="color-primary text-size-1">
          {{ $STR.lblNombreVisitante }}
        </v-card-title> -->
        <v-card-subtitle class=" text-center pa-0" v-if="!isNuevoVisitante || oInfoVisitante.NumIdentificacion != ''" >
          <span class="color-primary text-size-1 mb-1 text-center bold"> 
            {{ $STR.lblNroIdentificacion }}:
          </span>
          <span class="color-primary">
            {{ oInfoVisitante.NumIdentificacion }}
          </span>
        </v-card-subtitle>
        <v-card-subtitle v-else class="item-padding-5">
          <v-text-field
            v-model="oInfoVisitante.NumIdentificacion"
            :rules="aReglasCampoString"
            :label="$STR.cmpCedulaObligatorio"
            :placeholder="$STR.lblCedula"
            required
            class="item-padding-5"
          />
        </v-card-subtitle>
        
        <v-card-subtitle class="text-center mt-1 pa-0" v-if="!isNuevoVisitante">{{ oInfoVisitante.NombrePersona }}</v-card-subtitle>
        <v-card-subtitle v-else>
          <v-text-field
            v-model="oInfoVisitante.NombrePersona"
            :rules="aReglasCampoString"
            :label="$STR.cmpNombreVisitanteObligatorio"
            :placeholder="$STR.lblNombreVisitante2"
            required
            dense
            filled
            outlined
            rounded
          />
        </v-card-subtitle>
        
        <v-card-subtitle v-if="!isNuevoVisitante" class="text-center pa-0">
          <!-- <span class="color-primary text-size-1 mb-1">
            {{ $STR.lblTipoVisitante }}
          </span> -->
          {{ oInfoVisitante.NombreTipo }}
        </v-card-subtitle>
        <v-card-subtitle v-else>
          <v-select
            v-model="oInfoVisitante.Tipo"
            :items="aDataTipoPersona"
            item-text="nombreTipoPersona"
            item-value="idTipoPersona"
            :label="$STR.cmpTipoVisitanteObligatorio"
            :placeholder="$STR.lblTipoVisitante"
            required
            dense
            filled
            outlined
            rounded
          />
        </v-card-subtitle>
        <!-- <v-card-title class="color-primary text-size-1 mb-1" v-if="nTipoRegistro===0">
          {{ $STR.cmpEstado }}
        </v-card-title> -->
        <v-card-subtitle v-if="nTipoRegistro===0" class="text-center">
          {{ $STR.lblPersonaDentro }}
        </v-card-subtitle>
        
      </v-card>
    </v-col>
    <!--fotos
      v-model="oInfoVisitante.FotoNueva"
      :sTitulo='$STR.lblAnexos'
      :nCantidadFotos="1"
      modo="Dialog"
      :isModalActivo="isFotoVisitante"
      @onCerrarDialog="isFotoVisitante = false"
      /-->
  </v-row>
</template>

<script>
import ReglasMixin from "@/mixins/ReglasMixin.js";
import Fotos from "@/components/Fotos.vue";
import ValidarBD from "@/utils/ValidarBD.js";

export default {
  name: "InformacionVisitante",
  components: {
    Fotos,
  },
  mixins: [ReglasMixin],
  props: {
    oInfoVisitante: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Boolean,
      default: false,
    },
    nTipoRegistro: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      aDataTipoPersona: [],
      isFotoVisitante: false,
      isNuevoVisitante: false,
      sFotoPersonaTemporal: ''
    };
  },
  mounted() {
    this.isNuevoVisitante = this.value;
    this.aDataTipoPersona = ValidarBD.getInfoTabla("TipoPersona");
  },
  methods: {
    onClickTomarFoto() {
      this.isFotoVisitante = true;
      // CAPTURA DE DATOS ANALYTICS
      this.$firebase
        .analytics()
        .logEvent("informacionInicialQR", { FotoVisitante: 1 });
    },
  },
  computed: {
    NuevaFoto() {
      return this.oInfoVisitante.FotoNueva;
    },
    sFotoPersona() {
      return this.oInfoVisitante.FotoPersona;
    },
  },
  watch: {
    value(NewValor) {
      this.isNuevoVisitante = NewValor;
    },
    sFotoPersona() {
      if(this.sFotoPersona.includes('http'))this.sFotoPersonaTemporal = this.sFotoPersona
    },
    isNuevoVisitante(NewValor) {
      this.$emit("input", NewValor);
    },
    NuevaFoto(NewValor) {
      if (NewValor.length > 0) {
        this.oInfoVisitante.FotoPersona = NewValor[0].Base64;
      } else {
        delete this.oInfoVisitante.FotoNueva
        this.oInfoVisitante.FotoPersona = this.sFotoPersonaTemporal;
      }
    },
  },
};
</script>
<style lang="scss">
.img-container {
  min-height: 0px !important;
}
.no-min-height {
  min-height: 0px !important;
}
.bold {
  font-weight: 600;
}
</style>