import store from '@/store/index.js'
import DinamicosUtils from '@/utils/DinamicosUtils.js'
import Hora_Fecha from '@/utils/Hora_Fecha'

// Toda la información que llega esta en string por eso hay que pasarla a objeto con JSON.parse()

export default {
  // con esta funcion extraigo toda la informacion que requiero para trabajar normalmente
  async getInfo(){
    this.getUsuario()
    this.getGrupos()              // primero siempre grupos
    this.getPreguntas()
    await DinamicosUtils.getPreguntasValores()
  },
  getInfoElementos(){
    this.getUsuario()
    this.getTiposPersona()
    this.getTipoElementos()
    this.getAccesos()
  },
  onGetIdSede(){
    return parseInt(IApp.onGetIdSede()) // eslint-disable-line
  },
  async getInfoControlActividades(){

    this.getUsuario()
    store.dispatch('setnIDSedeBase', this.onGetIdSede())

    //let sData = await this.onEjecutarQuery("DatabaseSeif", `SELECT idActividadCalendario, detalle FROM CA_Actividades_Calendario WHERE sFechaProgramada = '${Hora_Fecha.getDia()}'`)
    let sData = await this.onEjecutarQuery("DatabaseSeif", `SELECT idActividadCalendario, sFechaProgramada, detalle FROM CA_Actividades_Calendario`)
    let sDataParametros = await this.onSelectTabla("DatabaseSeif", "parametrosgenerales", "")
    //let sDataParametro = await this.onSelectTabla("DatabaseSeif", "parametrosgenerales", "parametro = 'QR_Activo' ")
    let sTipoApp = this.onGetTipoApp()
    store.dispatch('setsTipoApp', (sTipoApp == "seifModulos") ? "1" : "0")

    let aDataParametros = JSON.parse(sDataParametros)
    const aParametros = aDataParametros.map((item) => {
      return {
        Parametro: item.parametro,
        Valor: item.Valor,
        Observacion: item.observacion
      }
    })
    store.dispatch('dataBase/salvarTabla', {tablaName : 'ParametrosGenerales', info: aParametros})

    const qrActivo = aParametros.find(item => item.Parametro == 'QR_Activo')

    store.dispatch('setbMarcacionQr', qrActivo.Observacion == "1")
    //console.log(sData);
    /*
    let aActividades = JSON.parse(sData.replaceAll('"detalle":"{', '"detalle":{')
                                       .replaceAll(']}}"}', ']}}}') // Si oRespuesta termina con un Array
                                       .replaceAll('}}}"}', '}}}}') // Si oRespuesta termina con un Object
    */
    let aActividades = JSON.parse(sData)
    aActividades.forEach((actividad, index) => {
      aActividades[index].detalle = JSON.parse(aActividades[index].detalle)  
    })
    store.dispatch('dataBase/salvarTabla', {tablaName : 'CA_Actividades_Calendario', info: aActividades})
  },
  async getTablasPaginadas(sTabla) {
    // Obtenemos la cantidad total de registros en la tabla
    let oCantidad = await this.onEjecutarQuery("DatabaseSeif", "select count(*) as cantidad from " + sTabla);
    let nCantidad = JSON.parse(oCantidad)[0].cantidad;

    const batchSize = 10;  // Tamaño del bloque
    let numPages = Math.ceil(nCantidad / batchSize);  // Número de bloques de 10 registros
    let aData = [];

    // Iteramos sobre los bloques
    for (let page = 0; page < numPages; page++) {
      let offset = page * batchSize;
      
      // Obtenemos los registros para la página actual (con un límite de 10 y desplazamiento 'offset')
      let sRegistro = await this.onEjecutarQuery("DatabaseSeif", 
          `SELECT * FROM ${sTabla} LIMIT ${batchSize} OFFSET ${offset}`
      );
      
      sRegistro = sRegistro.replace(/\\/g, '') // Escapar las barras invertidas
      .replace(/\n/g, '')      // Escapar saltos de línea
      .replace(/\r/g, '')      // Escapar retornos de carro
      .replace(/\t/g, '');     // Escapar tabulaciones


      let aTempData = JSON.parse(sRegistro);
      aTempData.forEach(item2 => {
        aData.push(item2);
      });

    }
    return aData;
  },
  async getTablasPWA(){

    try {
      this.getUsuario();
      let sTipoApp = this.onGetTipoApp()
      store.dispatch('setsTipoApp', (sTipoApp == "seifModulos") ? "1" : "0")
      //Validamos si ya esta precargado la informacion

      let aFormularios = store.state.dataBase.dataBase.find(item => item.tablaName=='FormularioApp')?.info;
      if (!aFormularios||aFormularios?.length==0) {
        let sFormularios = await this.onSelectTabla("DatabaseSeif", "FormularioApp", "")
        aFormularios = JSON.parse(sFormularios)
        store.dispatch('dataBase/salvarTabla', {tablaName : 'FormularioApp', info: aFormularios})
      }


      let aSedes = store.state.dataBase.dataBase.find(item => item.tablaName=='sede')?.info;
      if(!aSedes||aSedes?.length==0){
        aSedes = await this.getTablasPaginadas("sede");
        let aSedesTemp = aSedes.map((item) => {
          return {
            idCliente:item.idProyecto,
            Nombre:item.Nombre,
            idciudad:item.idCiudad,
            idsede:item.id,
          }
        })
        store.dispatch('dataBase/salvarTabla', {tablaName : 'sede', info: aSedesTemp})
      }

      let aClientes = store.state.dataBase.dataBase.find(item => item.tablaName=='Clientes')?.info;
      if(!aClientes||aClientes?.length==0){
        aClientes = await this.getTablasPaginadas("Clientes");
        let aClientesTemp = aClientes.map((item) => {
          return {
            NIT:item.nit,
            Nombre_Cliente:item.Nombre,
            idCliente:item.idproyecto,
          }
        });
        store.dispatch('dataBase/salvarTabla', {tablaName : 'Clientes', info: aClientesTemp})
      }


      let aCiudades = store.state.dataBase.dataBase.find(item => item.tablaName=='Ciudades')?.info;
      if(!aCiudades||aCiudades?.length==0){
        aCiudades = await this.getTablasPaginadas("Ciudades");
        let aCiudadesTemp = aCiudades.map((item) => {
          return {
            idCiudad:item.id,
            NombreCiudad:item.NombreCiudad,
          }
        })
        store.dispatch('dataBase/salvarTabla', {tablaName : 'Ciudades', info: aCiudadesTemp})
      }

      let aDatosGenerales = store.state.dataBase.dataBase.find(item => item.tablaName=='TablasGeneralesApp')?.info;
      if(!aDatosGenerales||aDatosGenerales?.length==0){
        aDatosGenerales = await this.getTablasPaginadas("TablasGeneralesApp");
        store.dispatch('dataBase/salvarTabla', {tablaName : 'TablasGeneralesApp', info: aDatosGenerales});
      }

      let aTiposCampos = store.state.dataBase.dataBase.find(item => item.tablaName=='TipoCampoApp')?.info;
      if(!aTiposCampos||aTiposCampos?.length==0){
        let sTiposCampos = await this.onSelectTabla("DatabaseSeif", "TipoCampoApp", "")
        aTiposCampos = JSON.parse(sTiposCampos)
        store.dispatch('dataBase/salvarTabla', {tablaName : 'TipoCampoApp', info: aTiposCampos})
      }


      let aEquivalencias = store.state.dataBase.dataBase.find(item => item.tablaName=='equivalencia')?.info;
      if(!aEquivalencias||aEquivalencias?.length==0){

        aEquivalencias = await this.getTablasPaginadas("tb_equivalencias")
        //Organizamos los datos de las equivalencias
        let aEquivalenciasTemp = aEquivalencias.map((item) => {
          return {
            descripción:"",
            en:item.en,
            es:item.es,
            idequivalencia:item.idEquivalencia,
            titulo:item.titulo,
            variable:item.variable
          }
        })
          
        store.dispatch('dataBase/salvarTabla', {tablaName : 'equivalencia', info: aEquivalenciasTemp})
        let oEquivalenciasTemp = {};
        aEquivalenciasTemp.forEach(oEquivalencia => {
          oEquivalenciasTemp[oEquivalencia.variable]=oEquivalencia.es;
        });
        store.dispatch('setoEquivalencia', oEquivalenciasTemp)
      }
      
      

    } catch (error) {
      console.log(error,'error');
      
    }

  },
  getAccesos(){
    const aTipoElementos = JSON.parse(IApp.getAccesos())  // eslint-disable-line
    store.dispatch('dataBase/salvarTabla', {tablaName : 'Accesos', info: aTipoElementos})
  },
  getTipoElementos(){
    const aTipoElementos = JSON.parse(IApp.getTipoElementos())  // eslint-disable-line
    store.dispatch('dataBase/salvarTabla', {tablaName : 'ELEMENTOS_TipoElemento', info: aTipoElementos})
  },
  getTiposPersona(){
    const aTipoPersona = JSON.parse(IApp.getTiposPersona())  // eslint-disable-line
    store.dispatch('dataBase/salvarTabla', {tablaName : 'TipoPersona', info: aTipoPersona})
  },
  getUsuario(){
    const aInfoUsuario = JSON.parse(IApp.getUsuario())  // eslint-disable-line
    
    // guardo en store
    store.dispatch('setRegistrarLogin', {
      permisos : [] ,
      iduser : aInfoUsuario.iduser,
      nombreusuario : aInfoUsuario.nombreusuario,
      idperfil : aInfoUsuario.idperfil,
      perfil :aInfoUsuario.perfil,
      idciudad :0,
      actualizaciones : [],
      sedes : [],
      sToken : aInfoUsuario.token
    })

  },
  getPreguntas(){
    
    const aPreguntas = JSON.parse(IApp.getPreguntas())  // eslint-disable-line
    
    // guardo en Database bajo el nombre de tabla : formularios-dinamicos/preguntas
    aPreguntas.forEach( (Pregunta,key) => {                                        
      
      if(Pregunta.idFORM_DINAMICOS_Tipos_campos == "6"){  // el item me llega como un String y se debe convertir a Objeto
        aPreguntas[key].Valor= JSON.parse(Pregunta.Valor)
      } else {
        aPreguntas[key].Valor = Pregunta.Valor.replaceAll('"','')  // Se hace por que el valor llega con " al principio y al final
      }
    })   
    store.dispatch('dataBase/salvarTabla', {tablaName : 'formularios-dinamicos/preguntas', info: aPreguntas})
  },
  getGrupos(){
    const aGrupos = JSON.parse(IApp.getGrupos())  // eslint-disable-line
    // guardo en Database bajo el nombre de tabla : FD_Grupos
    store.dispatch('dataBase/salvarTabla', {tablaName : 'FD_Grupos', info: aGrupos})
  },
  getValores(Valor){
    // Entrega la informacion de la tabla que se le solicite en Valor
    return JSON.parse(IApp.getValores(Valor))  // eslint-disable-line
  },
  enviarDataOffline(oBody, sFormulario='Formulario',sUrl="") {
  // se recibe la data como objeto JSON y se debe pasar como sting
    IApp.onGuardarDataOffline(JSON.stringify(oBody), sFormulario,sUrl) // eslint-disable-line
  }, 
  enviarFotosOffline(fFile){
    IApp.onIniciarCargarFotos(fFile) // eslint-disable-line
  },
  enviarFirma(sFirma){
    IApp.onGuardarFirma(sFirma) // eslint-disable-line
  },
  // este metodo es con el cual administro el metodo de envio al Celular segun el tipo 
  registro_OK(){
    IApp.onNotificarRegistroExitoso() // eslint-disable-line
  },
  onLeerQR(){
    IApp.onLeerQR() // eslint-disable-line
  },
  onLeerNFC(){
    IApp.onIniciarLeerNFC() // eslint-disable-line
  },
  onIniciarLeerNFC(){ 
    IApp.onIniciarLeerNFC() // eslint-disable-line
  },
  onDetenerLeerNFC(){
    IApp.onDetenerLeerNFC() // eslint-disable-line
  },
  getIMEI(){
    return IApp.getImei() // eslint-disable-line
  },
  getSubDominio(){
    return IApp.getSubDominio() // eslint-disable-line
  },
  Notificacion_token_error(){
    IApp.onNotificarErorToken() // eslint-disable-line
  },
  cancel_Form(){
    //validamos que la variable existe teniendo encuenta que no siempre se abre desde la apk
    if(typeof IApp !== "undefined"){
      IApp.onNotificarFormularioCancelado() // eslint-disable-line
    }
  },
  getIdFormulario(){
    if(typeof IApp !== "undefined"){
      if (typeof IApp.getIdFormulario === 'function') {
        return  IApp.getIdFormulario();
      }else{
        return 0
      }
    }else{
      return 0
    }
  },
  onGuardarLog(sLog){
    if(typeof IApp !== "undefined"){
      return IApp.onGuardarLog(sLog) // eslint-disable-line
    }
  },
  onSelectTabla(sBDname, sTabla, sWhere){
    return new Promise((resolve, reject) => {
      if (IApp) {
          // Define un nombre único para el callback
          const callbackName = 'callback_' + new Date().getTime();
          
          // Define la función de callback y la registramos en la instancia actual de Vue para invocarla luego desde la App
          window[callbackName] = function(result) {
              resolve(result);
              // Borramos el callback después de la ejecución de la App
              delete window[callbackName];
          };
          
          // Llama a la función de Android y pasamos el nombre del callback
          IApp.onSelectTabla(sBDname, sTabla, sWhere, callbackName)  // eslint-disable-line
      } else {
          reject("AndroidInterface no disponible");
      }
    });
  },
  onEjecutarQuery(sBDname, sQuery){
    return new Promise((resolve, reject) => {
      if (IApp) {
          // Define un nombre único para el callback
          const callbackName = 'callback_' + new Date().getTime();
          
          // Define la función de callback y la registramos en la instancia actual de Vue para invocarla luego desde la App
          window[callbackName] = function(result) {
              resolve(result);
              // Borramos el callback después de la ejecución de la App
              delete window[callbackName];
          };
          
          // Llama a la función de Android y pasamos el nombre del callback
          IApp.onEjecutarQuery(sBDname, sQuery, callbackName)  // eslint-disable-line
      } else {
          reject("AndroidInterface no disponible");
      }
    });
  },
  onGetTipoApp(){
    
      return IApp.onGetTipoApp() // eslint-disable-line
    
  },
  // La funcion onGuardarEnPendientes pronto debera usar un callback
  // para devolver el idPendiente generado en la app. Por ahora solo se usa cuando el
  // registro sube exitosamente y solo resta subir las fotos por ende mandamos id=0 por defecto
  onSubirFotosApp(sFormulario, idPendiente){
    IApp.onSubirFotosApp(sFormulario, idPendiente) // eslint-disable-line
  },
  onIniciarDictadoVozApp(){
    if(typeof IApp !== "undefined"){
      return IApp.onIniciarDictadoVozApp() // eslint-disable-line
    }
  },
  onGetLocalizacionActual(){
    return new Promise((resolve, reject) => {
      if (IApp) {
          // Define un nombre unico para el callback
          const callbackName = 'callback_' + new Date().getTime();
          // Define la función de callback y la registramos en la instancia actual de Vue para invocarla luego desde la App
          window[callbackName] = function(result) {
              // Sabemos que recibimos un JSON en String, entonces lo parseamos a un Objeto.
              // Para mantener compatibilidad con el componente GPSLocation.js, el resultado se devuelve en otro objeto
              const oCorrdenadas =  { coords: JSON.parse(result) } 
              resolve(oCorrdenadas);
              // Borramos el callback después de la ejecución de la App
              delete window[callbackName];
          };
          // Llama a la función de Android y pasamos el nombre del callback
          IApp.onGetLocalizacionActual(callbackName)  // eslint-disable-line
      } else {
          reject("AndroidInterface no disponible");
      }
    });
  },
  onPasarData(sData){
    IApp.onPasarData(sData) 
  }

}