import oTiposCampos from '@/components/dinamicos/TiposCampos.js'
import ValidarBD from '@/utils/ValidarBD.js'
import axiosServices from "@/services/axiosServices.js"
import store from '@/store/index.js'
import APPutils  from '@/utils/APPutils.js'


// let getdataBase = store.state.dataBase.dataBase
var FD_Preguntas = ValidarBD.getInfoTabla('formularios-dinamicos/preguntas')
var FD_Grupos = ValidarBD.getInfoTabla('FD_Grupos')
// const FD_Formulario = ValidarBD.getInfoTabla('FD_Formulario')

export default {

  getTipoCampo(nTipoCampo){
    return oTiposCampos[nTipoCampo]

  },
  getGrupo(idGrupo){
    return  FD_Grupos.find(grupos => grupos.idFD_Grupo == idGrupo).NombreGrupo
  },
  getFormulario(idformulario) {
    let aFormulario = []
    FD_Preguntas = ValidarBD.getInfoTabla('formularios-dinamicos/preguntas')
    FD_Grupos = ValidarBD.getInfoTabla('FD_Grupos')
    if(Object.keys(FD_Preguntas).length > 0 && idformulario > 0) {
      let idGrupo = null
      FD_Preguntas.forEach( campo => {
        if(idGrupo != campo.idFORM_DINAMICOS_GRUPOS && campo.idFORM_DINAMICOS_Nombre_Formulario == idformulario){
          idGrupo = campo.idFORM_DINAMICOS_GRUPOS
          aFormulario.push(
            {
              Modifica_A_ID  : "0",
              Obligatorio    : "0",
              Pregunta       : this.getGrupo(idGrupo),
              TipoCampo      : "Grupo",
              Valor          : "",
              idFORM_DINAMICOS_Formulario   : '',
              idFORM_DINAMICOS_GRUPOS       : campo.idFORM_DINAMICOS_GRUPOS,
              idFORM_DINAMICOS_Nombre_Formulario  : campo.idFORM_DINAMICOS_Nombre_Formulario,
              idFORM_DINAMICOS_Preguntas    : '',
              idFORM_DINAMICOS_Tipos_campos : "0",
              error          : false,           // propiedad empleada para marcar los campos obligatorios que no se llenan 
            }
          )
        }
        if(campo.idFORM_DINAMICOS_Nombre_Formulario == idformulario){
          campo.TipoCampo = this.getTipoCampo(campo.idFORM_DINAMICOS_Tipos_campos)
          campo.error = false
          aFormulario.push(campo)
        }
      })
    }
    return aFormulario 
  },
  async getPreguntasValores (nPagina = 1){
    // primero toca recorre la Tabla de preguntas valores y solicitar 
    let aPreguntas = ValidarBD.getInfoTabla('formularios-dinamicos/preguntas');

    await aPreguntas.forEach( async (pregunta) =>{ 
          if(pregunta.idFORM_DINAMICOS_Tipos_campos != 6){
            await this.ProcesarPregunta( pregunta.Valor.split("_") , pregunta.Valor)
          } else {
            const aInfoSubFormulario = pregunta.Valor[0]
            await aInfoSubFormulario.forEach( async pregunta_SubF => {
              await this.ProcesarPregunta(pregunta_SubF.Valor.split("_") , pregunta_SubF.Valor)
            })
          }
    })
  },
  async ProcesarPregunta(sufijo, Valor, nPagina = 1){
    if (sufijo[0] == "tb" || sufijo[0] == "td" || sufijo[0] == "tds"){
      const respuesta = await this.DescargarValor(Valor, nPagina)
      if(respuesta !== false) {
        store.dispatch('dataBase/salvarTabla', {tablaName : Valor, info: respuesta})
        return true
      } 
    }
    return false
  },
  async DescargarValor (Valor, nPagina = 1) {
    // valido si estoy en modo APP solicitar de una sin axios.
    if(store.state.isAPP){
     return APPutils.getValores(Valor)
    }

    let aux_respuestaAxios = {}
    aux_respuestaAxios = await axiosServices.getConfiguration('/formularios-dinamicos/preguntas/valores', true, `tabla=${Valor}&sede=${store.state.nIDSedeBase}&pagina=${nPagina}`)
    if (aux_respuestaAxios.sStatus == 'success'){
      return ((Array.isArray(aux_respuestaAxios.aData))) ? aux_respuestaAxios.aData : aux_respuestaAxios.aData.aData
    } else {
      // algo fallo salgo del for
      return false
    }
      

  },
  getInfo_aData(aData, idPregunta){
    // esta funcion regresa la infor de aData en base a su  id Pregunta
    return aData.find( registro => {
      if((!!registro) && (registro.IdPregunta == idPregunta)){
        return registro
      }
    })
  },

}