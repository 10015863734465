// https://github.com/fingerprintjs/fingerprintjs
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const fpPromise = FingerprintJS.load()

export default {

  async getIMEI() {
    
    const fp = await fpPromise
    const result = await fp.get()
  
    // This is the visitor identifier:
    return result.visitorId
  }

}