<template>
    <div>
        <span class="text-label-primary">
            {{ sLabel }} <span v-if="bObligatorio" class="red--text">*</span>
        </span>
        <v-text-field
            v-model="valor"
            v-bind="oAtributos"
            @keydown="onPrevenirSignos"
            class="mt-1"
            type="number"
            single-line
            dense
        />
    </div>
</template>
<script>
    export default {
        name: 'FormNumero',
        props: {
            value: {
            },
            oAtributos:{
                type:Object,
                default:()=>{}
            },
            bObligatorio:{
                type: Boolean,
                default: false,
            },
            sLabel:{
                type: String,
                default: "",
            },
        },
        data() {
            return {
                valor: this.value,
            }
        },
        watch: {
            valor(NewValor) {
                this.$emit('input', NewValor)
            },
            value(NewValor){
                this.valor = NewValor
            }
        },
        methods : {
            onPrevenirSignos(event) { // Evita insertar el simbolo +/- en los input number
                const key = event.key
                if (key === "+" || key === "-") {
                    event.preventDefault()
                }
            },
        }
    }
</script>