<template>
    <div>
      <v-col class="pa-0" cols="12">
        <v-alert class="text-caption font-weight-medium text-center" color="#ebedf0" dense>
          {{ sLabel }}
        </v-alert>
      </v-col>
    </div>
</template>
  
<script>
    export default {
        name: 'FormLabelDescripcion',
        props: {
            sLabel:{
                type: String,
                default: "",
            },
        }
    }
</script>
<style>
.text-azul {
        color: #003743;
    }
</style>