<template>
<ContenedorCampos :titulo="titulo" :isObligatorio="Obligatorio" :error="error">
  <v-col
    cols="12"
    lg="6">
    <v-menu
      v-model="showCalendar"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedDateFormatted"
          label="Seleccione una Fecha"
          hint="YYYY/MM/DD"
          persistent-hint
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        @input="showCalendar = false"
      ></v-date-picker>
    </v-menu>
  </v-col>
</ContenedorCampos>
</template>
<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'

export default {
  name: 'Calendarios',
  mixins: [ DinamicosMixin ], 
  components: {
    ContenedorCampos,
  },  
  data () {
    return {
      showCalendar : false,
      // date: new Date().toISOString().substr(0, 10),
      date: null,
    // }
    }
  },
  mounted () {
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },

  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },
  watch: {
    date(newDate){
      this.selectValue = newDate
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
