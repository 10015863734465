export default {
    0    : "Grupo",
    1	 : "ToggleButton",
    2	 : "Select",
    3	 : "Foto",
    4	 : "MultiSelect",
    5	 : "CheckBox",
    6	 : "Item",
    7	 : "EditTextNumeros",
    8	 : "EditTextTexto",
    9	 : "EditTextAlfanumerico",
    10   : "Calendarios",
    11   : "RadioButton",
    12   : "Firma",
    13   : "Informacion",
    14   : "Correo",
    15   : "Imagen",
}