<template>
<v-container fluid>
  <v-row class="no-gutters text-center">
    <v-col cols="12">
      <h3 class="titulo-contendor">¿Que deseas hacer?</h3>
    </v-col>
  </v-row>
  <v-row class="no-gutters py-3">
    <v-col cols="12">
      <div class="pa-2 item-boton back-gray border-radius__a d-flex justify-start align-center animate__animated animate__fadeInUp" :alt="'bt-pqrfinal-crear-ticket'"
      @click="aNuevoTicket"
      >
        <div class="cont-icon animate__animated animate__flipInY animate__delay-1s"><v-icon size="50px" color=" primary">mdi-clipboard-plus-outline</v-icon></div>
        <div class="cont-titulo"><span>Nuevo Ticket</span></div>
      </div>
    </v-col>
    <v-col cols="12" class="mt-2">
      <div class="pa-2 item-boton back-gray border-radius__a d-flex justify-start align-center animate__animated animate__fadeInUp"
      @click="aConsultarTicket"
      >
        <div class="cont-icon animate__animated animate__flipInY animate__delay-1s"><v-icon size="50px" color="primary">mdi-clipboard-search-outline</v-icon></div>
        <div class="cont-titulo"><span>Consultar Ticket</span></div>
      </div>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
// import FormNombre from './PqrFormNombre.vue'
import { mapGetters } from 'vuex'
export default {
  components:{
    // FormNombre
  },
  data() {
    return {
      valor: null,
      nitValidado: false
    }
  },
  mounted() {
    this.$store.dispatch('saveLinkStatus', true);
    this.$store.dispatch('saveLinkHeader', 'pqrcliente');
    this.$store.dispatch('savesPerfil', 'Hola '+this.$store.state.oPqrInfo.sNombre+'')
  },
  destroyed() {
    this.$store.dispatch('saveLinkStatus', false);
  },
  methods: {
    aNuevoTicket(){
      this.$router.replace('/nuevoTicket')
      this.$firebase.analytics().logEvent('PQRInicioTicket', { Nuevo:1})
    },
    aConsultarTicket(){
      this.$router.replace('/consultarTicket')
      this.$firebase.analytics().logEvent('PQRInicioTicket', { Consultar:1})
    }
  },
  computed:{
    ...mapGetters(['saveLinkStatus']),
  }
  
}
</script>
<style lang="scss" scoped>
.cont-titulo{
  color: #0E3143;
}
.item-boton{
  cursor:pointer;
}
</style>