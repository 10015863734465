<template>
  <v-row no-gutters justify="center">
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="color-gray-light text-size-1 my-2">
      {{$STR.stlInfoVehiculos}}
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
      <v-alert prominent text color="error" icon="mdi-alert-circle-outline" >
        <v-row align="center">
          <v-col class="text-size-05">
            {{$STR.textoElementosSeleccionar}}
          </v-col>
        </v-row>
      </v-alert>    
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="justify-center">
      <v-list class="px-1">
        <v-row>
          <v-col v-for="(item, index) in aInfoElementos" :key="index" cols="12" md="6">
            <v-list-item>
              <v-card outlined width="100%" class="mx-auto card-rounded card-shadown over-hidden my-1">
                <v-row>
                  <v-col cols="2" sm="2" md="1" class="d-flex justify-center align-center text-xs-center back-blue-light">
                    <div class="continer-check">
                      <v-checkbox class="margin-auto" v-model="item.isSeleccionado" hide-details />
                    </div>
                  </v-col>
                  <v-col cols="10" sm="10" md="11" >
                    <v-card-title class="justify-space-between">
                      <v-chip label small :color="item.estado == '1' ? 'success' : 'warning'">
                        {{ item.estado == '1' ? $STR.lblIngresoOff : $STR.lblIngresoOn }}
                      </v-chip>
                      <v-btn color="primary" light small rounded @click="onClickEditarElemento(item, index)" >
                        <v-icon left>mdi-pencil</v-icon> {{ $STR.lblEditar }}
                      </v-btn>
                    </v-card-title>
                    <v-col cols="12" class="ma-0 pa-0">
                      <v-card-title class="px-2 py-0 color-primary text-size-1"> {{ $STR.stlTipoElemento }}</v-card-title>
                      <v-card-subtitle class="pa-1 px-2 mt-1 text-size-1">{{ item.TipoElemento }}</v-card-subtitle>
                    </v-col>
                    
                    <v-row >
                      <v-col cols="4">
                        <v-card-title class="pa-2 color-primary text-size-1">{{ $STR.stlColor }}</v-card-title>
                        <v-card-subtitle class="pa-1 px-2 mt-auto text-size-1">{{ item.Color }}</v-card-subtitle>
                      </v-col>
                      <v-col cols="5" class="justify-center align-center">
                        <v-card-title class="pa-2 color-primary text-size-1">{{ $STR.stlMarca }}</v-card-title>
                        <v-card-subtitle class="pa-1 px-2 mt-auto text-size-1">{{ item.Marca }}</v-card-subtitle>
                      </v-col>
                      <v-col cols="3">
                        <v-card-title class="pa-2 color-primary text-size-1">QR</v-card-title>
                        <vue-qr :text="item.QR" :qid="item.QR" height="40px"></vue-qr>
                      </v-col>
                    </v-row>
              
                    <v-list-item class="">
                      <div class="foto-elemento pl-1" v-if="item.Fotos.length >0">
                        <v-img
                          class="img-container-rounded"
                          :src="item.Fotos[0].NombreAnexo"
                          width="90"
                          height="90"
                          @click="onClickMostrarFotos"
                        />
                      </div>
                      <v-img
                        class="img-container-rounded"
                        v-else
                        :src="require('@/assets/logo.png')"
                        height="200px"
                      />
                    </v-list-item>

                    <v-card-title class="pa-2 color-primary text-size-1">
                      {{ $STR.cmpObs }}
                    </v-card-title>
                    <v-card-subtitle class="pa-1 px-2">{{ item.Detalle }}</v-card-subtitle>
                    
                    <v-card-actions class="justify-center mt-3" />
                  </v-col>
                </v-row>
              </v-card>
              <listado-fotos
                v-if="isListadoFotos"
                v-model="isListadoFotos"
                sNombreCampo="NombreAnexo"
                :aInfoFotos="item.Fotos" 
              />
            </v-list-item>
          </v-col>
        </v-row>
      </v-list>
    </v-col>
    <v-col class="under-sec-rounded py-2 text-center my-2 py-1 px-2" cols="12">
      <p class="text-left text-regular color-gray-light text-size-1">{{$STR.stlInfoNuevoElemento}}</p>
       <v-speed-dial
        v-model="fab"
        :direction="'top'"
        class="boton-plus"
        :transition="'slide-y-reverse-transition'"
        z-index
      >
        <template v-slot:activator>
          <v-btn
            class="margin-auto"
            v-model="fab"
            dark
            fab
            color="primary"
          >
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <v-btn
          dark
          large
          class="btn-rounded margin-auto text-semibold my-1"
          color="#161E33"
          @click="onClickNuevoElemento"
        >
          Nuevo Elemento <v-icon right size="25">mdi-sticker-outline</v-icon>
        </v-btn>
        <v-btn
          dark
          large
          class="btn-rounded margin-auto text-semibold my-1" 
          color="#161E33"
          @click="onClickLeerQR"
        >
          Leer QR <v-icon right size="25">mdi-qrcode-scan</v-icon>
        </v-btn>
      </v-speed-dial>
     
    </v-col>
      <nuevo-elemento
        v-model="isNuevoElemento"
        @onAgregarNuevoVisitante="onAgregarNuevoVisitante"
        :idTipoVisitante="idTipoVisitante"
        :isModal="true"
        :onValidarQRElementosInsertados="onValidarQRElementosInsertados"
      />

      <editar-elemento
        v-if="isEditarElemento"
        v-model="isEditarElemento" 
        @onGuardar="onElementoActualizado"
        @onCancelar="onCerrarEditarElemento"
        :oElemento="oElemento"
        :onValidarQRElementosInsertados="onValidarQRElementosInsertados"
      />

      <mostrar-qr
        v-model="isMostrarQR"
        :sQR="sQRActual"
      />
      <lectura-qr
        v-if="isLecturaQR" 
        :activo="isLecturaQR"
        :onLecturaCompletada="onCargarInfoQR"
        @cerrarQR="cerrarlectorqr"
      />
   </v-row>
</template>

<script>
import NuevoElemento from '@/components/elementos-qr/NuevoElemento.vue'
import EditarElemento from '@/components/elementos-qr/EditarElemento.vue'
import MostrarQR from '@/components/MostrarQR.vue'
import LecturaQR from '@/components/LecturaQR.vue'
import ListadoFotos from '@/components/ListadoFotos.vue'
import axios from '@/services/axiosServices.js'
import VueQr from 'vue-qr';

export default {
  name: 'InformacionElementos',
  components: { 
    NuevoElemento,
    EditarElemento,
    'mostrar-qr': MostrarQR,
    'lectura-qr': LecturaQR,
    ListadoFotos,
    VueQr
   },
  props: {
      aInfoElementos: {
        type: Array,
        default: ()=> []
      },
      idTipoVisitante: {
        type: String,
        defalt: ''
      }
    },
  data () {
		return {
      fab: false,
      isNuevoElemento: false,
      isEditarElemento: false,
      oElemento:null,
      isMostrarQR: false,
      isLecturaQR: false,
      sQRActual: '',
      nIndexActual: -1,
      sTipoLectura: '',
      isListadoFotos: false
    }
	},
	mounted() {
   
  },
  methods: {
    onClickCambiarQR (index) {
      this.nIndexActual = index
      this.isLecturaQR = true
      this.sTipoLectura = 'CambiarQR'
      // CAPTURA DE DATOS ANALYTICS
      this.$firebase.analytics().logEvent('registroElementoQR', { CambiarQR:1})
    },
    onClickVerQR (sQR) {
      this.isMostrarQR = true
      this.sQRActual = sQR
      // CAPTURA DE DATOS ANALYTICS
      this.$firebase.analytics().logEvent('registroElementoQR', { VerQR:1})
    },
    onClickLeerQR(){
      this.isLecturaQR = true
      this.sTipoLectura = 'LeerQR'
      // CAPTURA DE DATOS ANALYTICS
      this.$firebase.analytics().logEvent('nuevoElemento', { NuevoManual:1})
    },
    onClickNuevoElemento(){
      this.isNuevoElemento = true
      // CAPTURA DE DATOS ANALYTICS
      this.$firebase.analytics().logEvent('nuevoElemento', { NuevoLeerQR:1})
    },
    onCargarInfoQR (sQR) {
      if(this.sTipoLectura == 'CambiarQR'){
        axios.getConfiguration(`elementos-qr/qr/${sQR}`, true, '').then( response =>{
          this.isCargando = false
          let mensaje = ''
          if (response.sStatus == 'success'){
            mensaje = response.sMessage
            if(response.aData == '1'){
              if(!this.onValidarQRElementosInsertados(sQR)){
                this.aInfoElementos[this.nIndexActual].QR = sQR
                this.aInfoElementos[this.nIndexActual].isNuevo = true
              }else{
                mensaje = "El Qr ya lo relacionó a otro elemento"
              }
            }
          }else{
            mensaje = this.$STR.msgErrorEnvio
          }
          this.$store.dispatch('notificaciones/SetMensaje', mensaje)
          this.$store.dispatch('notificaciones/ShowNotificacion',true) 
        })
        
        this.isLecturaQR = false
      }else if(this.sTipoLectura == 'LeerQR'){
        this.isLecturaQR = false
        this.$emit('LecturaQR', sQR)
      }
    },
    onValidarQRElementosInsertados (sQR){
      let isEncontrado = false
      this.aInfoElementos.forEach(item =>{
        if (item.QR.includes(sQR)) isEncontrado = true
      })
      return isEncontrado
    },
    onAgregarNuevoVisitante (item) {
      item.Fotos.forEach(foto => {
        foto.NombreAnexo = foto.Base64
      })
      this.aInfoElementos.push(item) 
    },
    onClickMostrarFotos () {
      this.isListadoFotos = true
    },
    cerrarlectorqr(){
      this.isLecturaQR = false
    },
    onClickEditarElemento(oItem, index){
      this.oElemento = oItem
      this.isEditarElemento = true
      this.nIndexActual = index
    },
    onElementoActualizado(oItemEditado) {
      if(this.nIndexElementoEdit>-1){
        this.aInfoElementos[this.nIndexActual] = oItemEditado
        this.aInfoElementos[this.nIndexActual].isNuevo = true
      }
      this.onCerrarEditarElemento()
    },
    onCerrarEditarElemento() {
      this.oElemento = null
      this.isEditarElemento = false
      this.nIndexActual = -1
    },
  },
   computed: {
  },
	watch: {
	},

}
</script>
<style lang="scss" scoped>
.v-list-item{
  padding: 0px!important;
}
.continer-check{
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-content: center;
  // border: solid red;
  margin-left: 0px;
  div{
    width: 10px;
    margin:0px!important;
    padding: 0px!important;
  }
  
}

.foto-elemento{
  height: 90px;
  max-width: 90px;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
}

</style>