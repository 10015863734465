<template>
    <v-layout row wrap justify-center align-center class="container-convensiones">
      <v-flex xs4 sm2>
        <h3>Convenciones</h3>
      </v-flex>
      <v-flex xs6 sm5 d-flex justify-center align-center>
        <div class="cont-icon animate__animated animate__flipInY" :class="'dura-'+index"  v-for="(conv,index) in convs" :key="'icon'+index">
          <v-icon color="primary" dense medium>
           {{conv.icon}}
          </v-icon>
        </div>
      </v-flex>
    </v-layout>   
</template>

<script>
  export default {
    name:"Convenciones",
    data() {
      return {
        convs:[
          {icon:'fas fa-exclamation-triangle'},
          {icon:'fas fa-cloud-download-alt'},
          {icon:'fas fa-check-circle'},
          {icon:'fas fa-times-circle'},
          ]
      }
    },
  }
</script>

<style lang="scss" scoped>
.row{
  margin: 0!important;
}
.cont-icon{
  border: solid 1px  var(--v-primary-base);
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 5px;
}
.container-convensiones{
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 3px 10px 0.6px rgba($color: #152232, $alpha: 0.2);
  padding: 5px 2px 5px 2px;
  h3{
    font-size: 14px;
    color: #0E3143;
    font-weight: 500;
  }
}
</style>