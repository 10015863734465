<template>
<ContenedorCampos :titulo="titulo" :isObligatorio="'false'" :error="error" class="under-sec">
  <v-layout row wrap justify-center align-center mt-1 mb-5>
    <v-img
      max-height="150"
      max-width="250"
      contain
      :src="`${aData}`"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="blue lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-layout>  
</ContenedorCampos>
</template>
<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'

export default {
  name: 'Imagen',
  mixins: [ DinamicosMixin ], 
  components: {
    ContenedorCampos,
  },  
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {

  },
  computed: {

  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
