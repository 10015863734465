<template>
  <ModuloItem :dataitem="itemshome"/>
</template>

<script>
import ModuloItem from "@/components/ModuloItem.vue"
import ValidarBD from '@/utils/ValidarBD.js'

  export default {
    name:'ListaDinamicos',
    components: {
      ModuloItem,
    },
    data(){
      return {
        itemshome : [],
        FD_Fomulario :  ValidarBD.getInfoTabla('FD_Formulario')
      }
    },
    mounted () {
      // genero el arreglo como lo requiere ModuloItem para mostrar la INFO.
      this.FD_Fomulario.forEach( formulario => {
        const oForm = {
          Icon:'mdi-book-outline',
          Titulo: '' ,
          Descripcion: formulario.NombreFormulario,
          Link:`/Dinamicos/${formulario.idFD_Formulario}`
        }
        this.itemshome.push(oForm)

      })
    },

  }
</script>

<style lang="scss" scoped>

</style>