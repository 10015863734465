import store from "@/store/index.js"
export default {
  async onDetenerFirebase(firebase){
    await firebase.app().delete();
  },
 async onEjecutarFirebase(firebase){
    const firebaseConfig = {
      apiKey: "AIzaSyCCTJmVdZ1Bh67fsoHOfsyBmlU8iBIaJtM",
      authDomain: "seif-modulos.firebaseapp.com",
      databaseURL: "https://seif-modulos.firebaseio.com",
      projectId: "seif-modulos",
      storageBucket: "seif-modulos.appspot.com",
      messagingSenderId: "315828580733",
      appId: "1:315828580733:web:62a3b3934d35313fcd9c14",
      measurementId: "G-503DPG1XL1"
    };
    await firebase.initializeApp(firebaseConfig);
    //Se valida si no estoy en al APP de android para cargar el FCM de firebase
    if(typeof IApp == 'undefined'){ // eslint-disable-line 
      if(firebase.messaging.isSupported()){
        const messaging = firebase.messaging()
        messaging.usePublicVapidKey("BB9lV7hPYjLUiULAxHbvReHH_lx9OfpUo5GMG0vDYV0twRUzpVwPcQ1ozoJOqutHg7f0kIET_RIqAIEqRyIzBzI")
        messaging.requestPermission().then(() => {
          // console.log('Notification permission granted.')
          messaging.getToken().then((token) => {
            // guardo en storage el token.
            // console.log(token)
            store.dispatch('settokenFirebase', token)
          })
        }).catch((err) => {
          alert(`Problemas con FCM: ${err}`)
          // console.log('Unable to get permission to notify.', err)
        })
        messaging.onMessage( payload => {
          // visualizacion mensaje
          store.dispatch('firebasenotificaciones/SetMensaje',{ title : payload.data.title, message : payload.data.message  } );
          store.dispatch('firebasenotificaciones/ShowNotificacion',true); 
        })
      } else {
        store.dispatch('firebasenotificaciones/SetMensaje',{ title : 'Notificación', message :'Su navegador no permite notificaciones, podra trabajar sin esta funcion. Se recomienda usar chrome en su ultima version. '  } );
        store.dispatch('firebasenotificaciones/ShowNotificacion',true); 
      }
    }
  }
}