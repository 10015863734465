// Son variable que se emplean de forma global en dinamcios

const state = () => ({
  aModificaA : [],    // esto es un arreglo donde guardo el valor de los campos modifica donde el indice el el idPregunta y su valor es el valor del campo seleccionado
  nModificaA : 0 ,    // debido a que monitorear por computed o watch un array no se puede a menos de cambie todo y no una propiedad creo esta variable para monitorear - se incrementa cada vez que cambia aModifica

})

const getters = {
  getaModificaA: ( state ) => {
    return state.aModificaA;
  },
  getnModificaA: ( state ) => {
    return state.nModificaA;
  },
} 

const mutations = {
  SALVAR_AMODIFICA : ( state, value ) => {
    state.aModificaA[value.idPregunta] = value.valor
    state.nModificaA++
  },
  CLEAR_AMODIFICA : (state) => {
    state.aModificaA = []
    state.nModificaA = 0
  }


}

const actions = {
  pushaModificaA( {commit}, value ) {
    commit( "SALVAR_AMODIFICA", value);
  },
  clearModificaA({commit}){
    commit("CLEAR_AMODIFICA")
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}