<template>
  <div>
    <v-row class="border-custom">
      <v-col cols="6">
        <span class="mdi mdi-map-marker-outline black--text">Puntos: {{ nPuntosRealizados }} de {{ nPuntosTotales }}</span> 
      </v-col>
      <v-col cols="6">
        <span class="mdi mdi-clock-time-three-outline black--text">Inicio: {{ sTipoHorario }}</span> 
      </v-col>
    </v-row>
  </div>
</template>
<script>
    export default {
        name: 'PuntosRealizados',
        props: {
            nPuntosRealizados: {
                type: Number,
                required: true
            },
            nPuntosTotales: {
                type: Number,
                required: true
            },
            sTipoHorario: {
                type: String,
                required: true
            },
        }
    }
</script>
<style lang="scss" scoped>
  .border-custom {
      border: 1px solid #e0e0e0;
  }
</style>
  