<template>
    <div>
      <v-col class="pa-0" cols="12">
        <v-alert class="font-weight-medium white--text text-center" color="#63A9E7" dense>
          {{ sLabel }}
        </v-alert>
      </v-col>
    </div>
</template>
  
<script>
    export default {
        name: 'FormLabelTitulo',
        props: {
            sLabel:{
                type: String,
                default: "",
            },
        }
    }
</script>
<style>
.text-azul {
        color: #003743;
    }
</style>