<template>
  <v-row>
    <v-dialog v-if="isModal" v-model="isNuevoElemento" fullscreen persistent hide-overlay transition="dialog-bottom-transition" >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="isNuevoElemento = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-size-2">{{ $STR.ttlNuevoElemento }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="12" xl="12" sm="12" lg="12">
                  <v-select
                    v-model="oElemento.idTipoElemento"
                    item-text="nombreTipoElemento"
                    item-value="idTipoElemento"
                    :items="aTipoElementos"
                    :label="$STR.cmpTipoElementoObligatorio"
                  />
                </v-col>
                <v-col cols="12" md="6" xl="6" sm="6" lg="6">
                  <v-text-field
                    v-model="oElemento.Marca"
                    :rules="aReglasCampoString"
                    :label="$STR.cmpMarcaObligatorio"
                    :placeholder="$STR.lblMarca"
                    required
                  />

                  <v-text-field
                    v-model="oElemento.Color"
                    :rules="aReglasCampoString"
                    :label="$STR.cmpColorObligatorio"
                    :placeholder="$STR.lblColor"
                    required
                  />

                  <v-textarea
                    v-model="oElemento.Detalle"
                    :label="$STR.cmpDetalle"
                    :placeholder="$STR.lblDetalle"
                    rows="3"
                    row-height="10"
                    required
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="6" xl="6" sm="6" lg="6">
                  <fotos
                    :key="oKeyFoto"
                    v-model="oElemento.Fotos"
                    :sTitulo="$STR.lblAnexos"
                    :nCantidadFotos="2"
                  />
                </v-col>

                <v-col cols="12" md="12" class="text-center under-sec-rounded">
                  <p class="color-primary text-size-1 mb-1">
                    {{ $STR.cmpQRObligatorio }}
                  </p>
                  <v-btn class="margin-auto" dark fab color="primary" @click="isLecturaQR = true" > 
                    <v-icon>mdi-qrcode-plus</v-icon>
                  </v-btn>
                  <p v-if="oElemento.QR != ''" class="text-size-1 mb-1">
                    {{ $STR.lblQrLeido }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn class="btn-primary btn-rounded margin-auto text-semibold" color="primary" dark block @click="onClickAñadir()" >
            {{ $STR.btnAñadir }}
            <v-icon right size="20">mdi-sticker-plus-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <loader v-model="isCargando" />
    </v-dialog>
    <LecturaQR
      v-if="isLecturaQR"
      :activo="isLecturaQR"
      :onLecturaCompletada="onCargarInfoQR"
      @cerrarQR="cerrarlectorqr"
    />
    <v-snackbar v-model="bMensaje">
      <p v-html="sMensaje"></p>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="bMensaje = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import ReglasMixin from "@/mixins/ReglasMixin.js";
import LecturaQR from "@/components/LecturaQR.vue";
import Fotos from "@/components/Fotos.vue";
import ValidarBD from "@/utils/ValidarBD.js";
import axios from "@/services/axiosServices.js";
import Loader from "@/components/Loader.vue";

export default {
  name: "NuevoElemento",
  mixins: [ReglasMixin],
  components: {
    LecturaQR,
    Fotos,
    Loader,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    idTipoVisitante: {
      type: String,
      defalt: "",
    },
    onValidarQRElementosInsertados: {
      type: Function,
    },
  },
  data() {
    return {
      isCargando: false,
      isLecturaQR: false,
      isNuevoElemento: false,
      aTipoElementos: [],
      oKeyFoto: 1,
      oElemento: {
        idTipoElemento: "",
        Marca: "",
        Color: "",
        Fotos: [],
        QR: "",
        isSeleccionado: true,
        Detalle: "",
      },
      sMensaje: "",
      bMensaje: false,
    };
  },
  mounted() {
    this.isLecturaQR = this.value;
    this.aTipoElementos = ValidarBD.getInfoTabla("ELEMENTOS_TipoElemento");
  },
  methods: {
    onClickAñadir() {
      if (!this.isFormularioValido()) {        
        this.isNuevoElemento = false;
        let item = this.oElemento;
        item.estado = "1"
        item.isNuevo = true;
        this.oElemento = {
          idTipoElemento: "",
          Marca: "",
          Color: "",
          Fotos: [],
          QR: "",
          isSeleccionado: true,
          Detalle: ""
        };
        this.oKeyFoto++;
        this.$emit("onAgregarNuevoVisitante", item);
      } else {
        this.bMensaje = true;
        //this.sMensaje = ''
        return;
      }
    },
    isFormularioValido() {
      this.sMensaje = "";
      let error = false;
      if (this.oElemento.idTipoElemento == "") {
        this.sMensaje += "- Tipo Elemento no seleccionado.<br>";
        error = true;
      }
      if (this.oElemento.Marca == "") {
        this.sMensaje += "- Marca no diligenciada.<br>";
        error = true;
      }
      if (this.oElemento.Color == "") {
        this.sMensaje += "- Color no diligenciado.<br>";
        error = true;
      }
      if (this.oElemento.Detalle == "") {
        this.sMensaje += "- Detalle no diligenciado.<br>";
        error = true;
      }
      /*if(this.idTipoVisitante!="1" && this.oElemento.QR==''){
        this.sMensaje += '- QR no escaneado.<br>'
        error = true
      }*/
      if (this.oElemento.Fotos.length == 0) {
        this.sMensaje += "- Fotos no tomadas.<br>";
        error = true;
      }

      return error;
    },
    onCargarInfoQR(sCodigo) {
      this.isLecturaQR = false;
      this.isCargando = true;
      axios
        .getConfiguration(`elementos-qr/qr/${sCodigo}`, true, "")
        .then((response) => {
          this.isCargando = false;
          let mensaje = "";
          if (response.sStatus == "success") {
            mensaje = response.sMessage;
            if (response.aData == "1") {
              if (!this.onValidarQRElementosInsertados(sCodigo)) {
                this.oElemento.QR = sCodigo;
              } else {
                mensaje = "El Qr ya lo relacionó a otro elemento";
              }
            }
          } else {
            mensaje = this.$STR.msgErrorEnvio;
          }
          this.$store.dispatch("notificaciones/SetMensaje", mensaje);
          this.$store.dispatch("notificaciones/ShowNotificacion", true);
        });

      //console.log("Lectura", sCodigo)
    },
    cerrarlectorqr() {
      this.isLecturaQR = false;
    },
  },
  watch: {
    value(NewValor) {
      this.isNuevoElemento = NewValor;
    },
    isNuevoElemento(NewValor) {
      this.$emit("input", NewValor);
    },
  },
};
</script>