<template>
  <v-dialog
    v-model="oModal.bMostrar"
    min-width="500px"
    transition="dialog-transition"
    persistent
  >
  <v-card class="pa-3">
    <v-card-text class="d-flex py-2 justify-center align-center ">
      <img src='/img/loading.png' class="icon-load-app">
    </v-card-text>
    <v-card-title class="pa-1 mt-2 text-medium pt-1 justify-center text-size-2 text-center">
      {{ oModal.sTitulo }}
    </v-card-title>
    <v-card-text class="px-3 my-2 text-medium justify-center text-size-1 text-center">
      {{ oModal.sMensaje }}
    </v-card-text>
  </v-card>
    
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ModalMensajeCargando',
  data() {
    return {
      oModal:{
        bMostrar: false,
        sTitulo: "Mi titulo",
        sMensaje: "Mi mensaje"
      }
    }
  },
  watch:{
    getoModalMensajeCargando(newValor){
      this.oModal = newValor
    },
  },
  computed:{
    ...mapGetters(['getoModalMensajeCargando'])
  }
}
</script>
<style lang="scss" scoped>
.cont-icono{
  background: #32BEA6;
  padding: 10px;
  border-radius: 50%;
}
.mensaje{
  color: var(--v-primary-base);
}
.texto-mensaje{
  line-height: 1.1em;
  font-size: 1.5em;
  color: var(--v-primary-base);
}
</style>