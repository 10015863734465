<template>
  <v-dialog
    v-if="modo == 'Dialog'"
    v-model="isModalActivo"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="onClickCerrar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="text-size-2">{{ $STR.ttlTomaFotos }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="pt-2">
        <ContenedorCampos :titulo="sTitulo" :isObligatorio="isObligatorio" class="under-sec" >
          <div class="my-2">
            <v-row>
              <v-col cols="12" v-for="(foto, index) in aFotos" :key="index">
                <img :src="foto.Base64" width="100%" @click="ampliar(foto.Base64)" />
                <v-btn dark small block color="red darken-2" class="mt-n2" @click="borrar(index)" icon>
                  <span>Eliminar</span>
                  <v-icon small dark color="white" right>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <CameraAPP v-if="getisAPP" @fotoCapture="verFoto"></CameraAPP>
          <Camera v-else @fotoCapture="verFoto"></Camera>
          <!-- MODAL PARA AMPLIAR FOTOS -->
          <AmpliarFoto
            :fotoampliada="ftampliada"
            :textoampliado="txtampliado"
            v-model="showFoto"
          ></AmpliarFoto>
        </ContenedorCampos>
      </v-card-text>
    </v-card>
  </v-dialog>
  <ContenedorCampos v-else :titulo="sTitulo" :isObligatorio="isObligatorio">
    <div class="mb-1">
      <v-row justify="center">
        <v-col v-for="(foto, index) in aFotos" :key="index" class="text-center">
          <v-avatar size="150px" >
            <v-img
              :src="foto.Base64"
              max-height="150px"
              max-width="150px"
              @click="ampliar(foto.Base64)"
            />
          </v-avatar>
          <div class="text-center">
            <!-- <img :src="foto.Base64" width="100%" @click="ampliar(foto.Base64)" /> -->
            <v-btn small icon color="red darken-2" class="mx-auto mt-1" @click="borrar(index)" outlined>
              <v-icon>mdi-delete</v-icon> 
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <CameraAPP
      v-if="getisAPP"
      @fotoCapture="verFoto"
      :nCantidadFotos="nCantidadFotos"
      :nFotosTomadas="this.aFotos.length"
    ></CameraAPP>
    <Camera
      v-else
      @fotoCapture="verFoto"
      :nCantidadFotos="nCantidadFotos"
      :nFotosTomadas="this.aFotos.length"
    />
    <!-- MODAL PARA AMPLIAR FOTOS -->
    <AmpliarFoto
      :fotoampliada="ftampliada"
      :textoampliado="txtampliado"
      v-model="showFoto"
    ></AmpliarFoto>
  </ContenedorCampos>
</template>
<script>
import ContenedorCampos from "@/components/dinamicos/ContenedorCampos.vue";
import Camera from "@/components/dinamicos/compAuxiliares/camera.vue";
import AmpliarFoto from "@/components/dinamicos/compAuxiliares/AmpliarFoto.vue";
import CameraAPP from "@/components/dinamicos/compAuxiliares/cameraAPP.vue";
import { mapGetters } from "vuex";

export default {
  name: "Foto",
  mixins: [],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    sTitulo: {
      type: String,
      default: "",
    },
    isObligatorio: {
      type: String,
      default: "0",
    },
    nCantidadFotos: {
      type: Number,
      default: 100,
    },
    modo: {
      type: String,
      dafault: "Normal",
    },
    isModalActivo: {
      type: Boolean,
      dafault: false,
    },
  },
  components: {
    ContenedorCampos,
    Camera,
    AmpliarFoto,
    CameraAPP,
  },
  data() {
    return {
      aFotos: [],
      ftampliada: null,
      txtampliado: null,
      showFoto: false,
      selectValue: this.value || null,
    };
  },
  mounted() {
    //console.log("APP", this.getisAPP)
  },
  methods: {
    verFoto(fotos) {
      if (this.aFotos.length < this.nCantidadFotos) {
        this.aFotos.push(fotos);
      } else {
        this.$store.dispatch(
          "notificaciones/SetMensaje",
          this.$STR.msgMaxFotos.replace("XXX", this.nCantidadFotos)
        );
        this.$store.dispatch("notificaciones/ShowNotificacion", true);
      }
    },
    borrar(index) {
      this.aFotos.splice(index, 1);
    },
    ampliar(val) {
      this.showFoto = true;
      this.ftampliada = val;
    },
    onClickCerrar() {
      this.isModalActivo = false;
      this.$emit("onCerrarDialog");
    },
  },
  computed: {
    ...mapGetters(["getisAPP"]),
  },
  watch: {
    aFotos(newFotos) {
      this.selectValue = newFotos;
      this.$emit("input", newFotos);
    },
  },
};
</script>

<style lang="scss" scoped>
.red {
  border: 1px solid red
}
.blue {
  border: 1px solid blue
}
</style>
