<template>
    <div>
        <span class="text-label-primary">
            {{ sLabel }} <span v-if="bObligatorio" class="red--text">*</span>
        </span>
        <v-textarea
            v-model="valor"
            v-bind="oAtributos"
            class="mt-1"
            dense
            auto-grow
            rows="1"
            @input="validateInput"
        />
    </div>
</template>

<script>
export default {
    name: 'FormTexto',
    props: {
        value: {
            type: String,
            default: ''
        },
        oAtributos: {
            type: Object,
            default: () => {}
        },
        bObligatorio: {
            type: Boolean,
            default: false
        },
        sLabel: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            valor: this.value
        }
    },
    watch: {
        valor(NewValor) {
            this.$emit('input', NewValor)
        },
        value(NewValor) {
            this.valor = NewValor
        }
    },
    methods: {
        validateInput() {
            // Remueve comillas simples y dobles
            this.valor = this.valor.replace(/['"]/g, '')
        }
    }
}
</script>

<style scoped>
/* Ajuste para que el textarea sea más compacto */
.v-textarea .v-input__control {
    min-height: 36px !important;  /* Ajusta la altura */
}

/* Si necesitas que el borde sea más delgado, puedes modificarlo aquí */
.v-textarea .v-input__control .v-input__slot {
    border-width: 1px !important;  /* Ajusta el grosor del borde */
}
</style>
