<template>
<v-container grid-list-xs container md-6 class="container-elementos">
  <v-row no-gutters justify="center">
    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
     <fecha-seccion />
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
     <pasos v-model="nPaso" :aPasos="aPasos" :onClickSteper="onClickSteper">
      <v-stepper-content step="1" class="item-padding-8 pb-10">
        <v-row no-gutters justify="center">
           <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="text-center" v-if="!Object.prototype.hasOwnProperty.call( this.oInfoVisitante, 'NombrePersona' )">
             <span>{{$STR.ttlValidaInfo}}</span>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <informacion-visitante
              v-model="isNuevoVisitante"
              :oInfoVisitante="oInfoVisitante"
              :nTipoRegistro="nTipoRegistro"
            />
          </v-col>
           <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <informacion-elementos 
              :aInfoElementos="aInfoElementos"
              :idTipoVisitante="oInfoVisitante.Tipo"
              @LecturaQR="onLecturaQR"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-btn color="primary" rounded class="mx-2 px-4 py-1 margin-auto" large block @click="onClickContinuar" :disabled="!cmp_bElementosIguales" >
              {{ $STR.btnSiguiente}}
              <v-icon size="20px" right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="2" class="item-padding-8 pb-10">
         <v-row no-gutters justify="center">
           <v-col cols="12" xs="12" sm="12" md="12" lg="12">
             <span class="color-primary text-regular text-size-2">
            {{$STR.ttlregistroSalidaEntrada}}
             </span>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
           <registro-entrada-salida 
            v-model="oInfoRegistro" 
            :isValidForm="cmp_validarElementosEntrada"
            :nValorElementos="nValorElementos"
           />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" class=" text-center my-2">
            <v-btn color="btncolor" class="btn-rounded mx-2 px-4 py-1 margin-auto" large dark block @click="onClickRegistrar" >
              {{ $STR.btnFinalizarRegistro}}
              <v-icon size="23px" right>mdi-flag-checkered</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
     </pasos>
    </v-col>
    <loader v-model="isCargando"/>
  </v-row>
</v-container>
</template>

<script>
import axios from '@/services/axiosServices.js'
import FuncionesBasicas from '@/mixins/FuncionesBasicas.js'
import FechaSeccion from '../../components/FechaSeccion.vue'
import Pasos from '../../components/Pasos.vue'
import InformacionVisitante from '../../components/elementos-qr/InformacionVisitante.vue'
import InformacionElementos from '../../components/elementos-qr/InformacionElementos.vue'
import RegistroEntradaSalida from '../../components/elementos-qr/RegistroEntradaSalida.vue'
import uFecha from '@/utils/Hora_Fecha.js'
import axiosServices from '@/services/axiosServices.js'
import APPutils from "@/utils/APPutils.js"

  export default {
    name:'RegistroElementos',
    components: {
      FechaSeccion,
      Pasos,
      InformacionVisitante,
      InformacionElementos,
      RegistroEntradaSalida
    },
    mixins : [ FuncionesBasicas ],
    data() {
      return {
        nPaso: 1,
        aPasos: [
        {
          id: 1,
          sId: '1',
          sTitulo: 'Paso 1',
          isFinal: false
        },
        {
          id: 2,
          sId: '2',
          sTitulo: 'Paso 2',
          isFinal: true
        }
        ],
        oInfoVisitante: {},
        aInfoElementos: [],
        aInfoElementosEncontrados: [],
        isNuevoVisitante: false,
        oInfoRegistro: {},
        nTipoRegistro: -1, // define el estado de acceso de la persona - elemento, -1 no tiene registro 0 está dentro, 1 ya tuvo salida
        nValorElementos: -1
      }
    },
    async mounted () {  
      this.isCargando = true
      axios.getConfiguration('elementos-qr/consultar', true, `tipo=${this.$route.query.q}&parametro=${this.$store.state.sQR}`).then( response =>{
        this.isCargando = false
        if (response != undefined && response.sStatus == 'success'){
          this.aInfoElementosEncontrados = response.aData.Elementos
          this.aInfoElementosEncontrados.forEach(item =>{
            if(item.isLecturaQR=="1"){
              item.isSeleccionado = true
              this.aInfoElementos.push(item)
            } 
          })
          this.nTipoRegistro = typeof response.aData?.validacion === 'object' ? parseInt(response.aData?.validacion?.tipo_registro) : -1
          if(response.aData.isPersona){
            this.oInfoVisitante = response.aData.Persona
            // if(this.oInfoVisitante.Tipo =="1"){
              this.aInfoElementos = this.aInfoElementosEncontrados
            // }
          }else{
            this.isNuevoVisitante = true
            if(this.$route.query.q == 'Cedula') {
              this.oInfoVisitante.NumIdentificacion = this.$store.state.sQR
              this.$store.dispatch('notificaciones/SetMensaje',this.$STR.msgVisitanteNoExistente )
            } else {
              let sMensajeQR = response.aData?.bQRDeshabilitado ? this.$STR.msgQRNoDisponible : this.$STR.msgQRNoExistente;
              this.$store.dispatch('notificaciones/SetMensaje', sMensajeQR)
            }
            this.$store.dispatch('notificaciones/ShowNotificacion',true) 
            if(this.$route.query.q=="QR"){
              this.$router.replace({name: 'ElementosDashboard'})
            }
          }
        }else{
          let sMensaje = 'Error en la conexión de la red. Reintente nuevamente o verifique su conexión a internet'
          if(typeof IApp !== 'undefined') {    // eslint-disable-line
            alert(sMensaje)
            setTimeout(() => {
              APPutils.cancel_Form()               
            }, 5000);
            
          }else{
            this.$store.dispatch('notificaciones/SetMensaje', sMensaje)
            this.$store.dispatch('notificaciones/ShowNotificacion',true)
          }
        } 
      })
      
    },
    methods: {
      onClickContinuar () {
        let error = false
        if (this.aInfoElementos.length==0) error=true
        if (Object.keys(this.oInfoVisitante).length==0) error=true
        if(this.oInfoVisitante.NombrePersona == '' || this.oInfoVisitante.NumIdentificacion == '') error=true
        if(typeof this.oInfoVisitante.NombrePersona == undefined || typeof this.oInfoVisitante.NumIdentificacion == undefined) error=true
        var items = 0;
        this.aInfoElementos.forEach( item => {
          if(item.isSeleccionado) items ++
        })
       if (items==0) error=true

        if(!error){
          this.nPaso = 2
          if(this.cmp_bElementosIguales) {            
            const aSeleccionados = this.aInfoElementos.filter((oItem) => oItem.isSeleccionado)
            this.nValorElementos = aSeleccionados[0].estado ? parseInt(aSeleccionados[0].estado) : -1  
                      
          }
        }else{
          this.$store.dispatch('notificaciones/SetMensaje',this.$STR.msgFaltanElementosVisitantes )
            this.$store.dispatch('notificaciones/ShowNotificacion',true) 
        }
        
      },
      onClickSteper(sId) {
        if(sId == "2"){
          this.onClickContinuar()
        }else{
          this.nPaso = 1
        }
      },
      onClickLecturaQR() {
        this.isLecturaQR = true
      },
      async onClickRegistrar() {
        let error = false
        if (Object.keys(this.oInfoRegistro).length==0) error=true
        if (this.oInfoRegistro.TipoRegistro=="" || !this.oInfoRegistro.TipoRegistro) error=true
        // if (this.oInfoRegistro.Observaciones=="" || !this.oInfoRegistro.Observaciones) error=true
        if (this.oInfoRegistro.idAcceso=="" || !this.oInfoRegistro.idAcceso) error=true
        if(!error){
          const prefijo = this.$store.state.sUserName;
          let aFotosGenerales = []
          let aFotosSubir = []
          let aElementosSubir = []
          let NombreFotoPersona = ''
          //recorremos fotos generalespara cargar la información
          if(this.oInfoRegistro.Fotos){
            this.oInfoRegistro.Fotos.forEach(item => {
              aFotosGenerales.push({ NombreFoto: `${prefijo}_${item.NombreFoto}`})
              aFotosSubir.push(item)
            })
          }
          if(this.oInfoVisitante.FotoNueva){
            aFotosSubir.push(this.oInfoVisitante.FotoNueva[0])
            NombreFotoPersona = `${prefijo}_${this.oInfoVisitante.FotoNueva[0].NombreFoto}`
            this.isNuevoVisitante = true
          }else{
            NombreFotoPersona = this.oInfoVisitante.FotoPersona
          }

          this.aInfoElementos.forEach(item =>{
            if(item.isSeleccionado){
              item.Fotos.forEach(subitem => {
                if(subitem.Base64){
                  const itemFotoElemento = {
                    Base64: subitem.NombreAnexo,
                    NombreFoto : subitem.NombreFoto
                  }
                  aFotosSubir.push(itemFotoElemento)
                  delete subitem.Base64
                  delete subitem.NombreAnexo

                }
                subitem.NombreFoto = `${prefijo}_${subitem.NombreFoto}`
              })
              aElementosSubir.push(item)
            }
          })
          const oDataEnvio = {
            app: 'RegistroElementosQR',
            FechaRegistro: uFecha.getNow(),
            TipoRegistro: this.oInfoRegistro.TipoRegistro,
            Observaciones: this.oInfoRegistro.Observaciones,
            idAcceso: this.oInfoRegistro.idAcceso,
            Fotos: aFotosGenerales,
            isNuevoVisitante: this.isNuevoVisitante,
            idPersona: this.oInfoVisitante.idPersona,
            NombrePersona: this.oInfoVisitante.NombrePersona,
            NumIdentificacion: this.oInfoVisitante.NumIdentificacion,
            FotoPersona: NombreFotoPersona,
            TipoPersona: this.oInfoVisitante.Tipo,
            Elementos: aElementosSubir
          }
          aFotosSubir.forEach( async foto => {
            axiosServices.postFoto(`${prefijo}_${foto.NombreFoto}`, foto.Base64)
          })
          const res = await axiosServices.postConfiguration('/elementos-qr/registro', oDataEnvio, false )
          if(res.nStatusCode == 200){
            if(typeof IApp !== 'undefined') {    // eslint-disable-line
              APPutils.registro_OK()
            }
            this.$router.replace({name: 'ElementosDashboard'})
          }else{
            if(typeof IApp !== 'undefined') {    // eslint-disable-line
              APPutils.enviarDataOffline({})
            }
            this.$store.dispatch('notificaciones/SetMensaje',this.$STR.msgErrorEnvio )
            this.$store.dispatch('notificaciones/ShowNotificacion',true) 
          }
          this.$firebase.analytics().logEvent('FinalizarRegistroElemento', { RegistroOK:1}) // CAPTURA DE DATOS ANALYTICS
        }else{
          this.$store.dispatch('notificaciones/SetMensaje',this.$STR.msgFaltanDatosregistro )
          this.$store.dispatch('notificaciones/ShowNotificacion',true)
          this.$firebase.analytics().logEvent('FinalizarRegistroElemento', { RegistroError:1}) // CAPTURA DE DATOS ANALYTICS
        }
      },
      onLecturaQR(sQR){
        let isExiste = false
        this.aInfoElementos.forEach(item => {
          if (item.QR==sQR) isExiste = true
        })
        if(isExiste){
          this.$store.dispatch('notificaciones/SetMensaje',this.$STR.msgQRLeido )
          this.$store.dispatch('notificaciones/ShowNotificacion',true) 
        }else{
          this.aInfoElementosEncontrados.forEach(item => {
            if (item.QR==sQR) {
              this.aInfoElementos.push(item)
              isExiste = true
            }
          })

          if(!isExiste){
            this.isCargando = true
            axios.getConfiguration('elementos-qr/consultar', true, `tipo=QR&parametro=${sQR}`).then( (response) => {
              this.isCargando = false
              if (response.sStatus == 'success') {
                let oItem = {}
                this.nTipoRegistro = typeof response.aData?.validacion === 'object' ? parseInt(response.aData?.validacion?.tipo_registro) : -1
                
                response.aData.Elementos.forEach( (item) => {
                  if(item.isLecturaQR=="1") {
                    isExiste = true
                    oItem = item
                  } 
                })
                if(response.aData.isPersona && isExiste){
                  if(this.oInfoVisitante.idPersona == response.aData.Persona.idPersona) {
                    this.aInfoElementos.push(oItem)
                  } else if((this.oInfoVisitante.Tipo == "4" && response.aData.Persona.Tipo=="4")){
                    this.aInfoElementos.push(oItem)
                  }else {
                    this.$store.dispatch('notificaciones/SetMensaje',this.$STR.msgQRNoPersona.replace('XXX',response.aData.Persona.NombrePersona).replace('YYY',response.aData.Persona.NumIdentificacion) )
                    this.$store.dispatch('notificaciones/ShowNotificacion',true) 
                  }
                }else {
                  let sMensajeQR = response.aData?.bQRDeshabilitado ? this.$STR.msgQRNoDisponible : this.$STR.msgQRNoExistente;
                  this.$store.dispatch('notificaciones/SetMensaje', sMensajeQR)
                  this.$store.dispatch('notificaciones/ShowNotificacion',true) 
                }
              } 
            })
            
          }
        }
        
      }
    },
    computed: {
      cmp_validarElementosEntrada() {
        const selectedItems = this.aInfoElementos.filter(item => item.isSeleccionado);
        // si no hay nada seleccionado retorna false
        if(selectedItems.length === 0) return false;
        // si todos los elementos seleccionados no tienen entrada
        return selectedItems.every(item => item.estado === '1');
      },
      cmp_bElementosIguales() { // indica si los elementos seleccionados son iguales
        const selectedItems = this.aInfoElementos.filter(item => item.isSeleccionado);
        if(selectedItems.length === 0) return false;
        return selectedItems.every(item => item.estado === selectedItems[0].estado );
      }

    },
  }
</script>

<style lang="scss" scoped>
.container-elementos{
  padding: 12px 0px;
}
.margin-auto{
  margin:0 auto!important;
}
</style>