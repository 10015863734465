<template>
  <div class="margen-form">
    <v-container :style="{ marginBottom : (!getbMenuBlock) ? '140px' : '90px'}" >
        <!--Seccion titulo-->
        <v-col class="pa-0 mb-2" cols="12">    
          <HeaderVista :sTitulo="sTitulo" sColor="black--text">
            <template v-slot:icono>
              <v-icon large color="red"> fas fa-map-marked-alt </v-icon>
            </template>
          </HeaderVista>
        </v-col>
        <!--Seccion contador-->
        <v-col class="mb-2" cols="12">
          <PuntosRealizados 
            :nPuntosRealizados = "nPuntosRealizados"
            :nPuntosTotales = "nPuntosTotales"
            :sTipoHorario = "sTipoHorario"
          />
        </v-col>
        <!--Seccion Cards Punto-->
        <v-col class="pa-0 mb-2" cols="12" v-for="punto in puntos">
          <PuntoItemCard 
            :oPunto="punto" 
            :bShowBtnDetalle="true">
            <template v-slot:informacion="attr">
              <InformacionPunto
                v-model="attr.bShowDetalle"
                :sIdPunto="punto.sIdPunto"
                :sDescripcion="punto.sDescripcion"
                :sFoto="punto.sFoto"
              />
            </template>
          </PuntoItemCard>

        </v-col>
    </v-container>
    <v-sheet
      :z-index="100"
      class="text-center contenedor-bottom"
      :height="(!getbMenuBlock) ? '140px' : '90px'"
      >
      <v-row no-gutters justify="center" align="center" class="mt-3" v-if="!bRondaCompletada">
        <v-col cols="6" xs="6" sm="4" md="4" lg="6" justify="center" align="center">
          <v-btn @click="onClickFinalizarRonda" large dark color="error" outlined class="btn-primary btn-rounded">
            {{ $STR.btnFinalizarRonda }}
          </v-btn>
        </v-col>  
        <v-col cols="6" xs="6" sm="4" md="4" lg="6" justify="center" align="center">
          <v-btn @click="onMarcarPunto" large dark color="primary" class="btn-primary btn-rounded">
            {{ $STR.btnMarcarPunto }}
          </v-btn>
        </v-col> 
      </v-row>
    </v-sheet>

    <LecturaQR 
      v-if="isLecturaQR" 
      v-model="isLecturaQR"
      :activo="isLecturaQR"
      :onLecturaCompletada="onLecturaCompletada" 
      @cerrarQR="cerrarlector"
    />
    <LecturaNFC 
      v-if="isLecturaNFC" 
      v-model="isLecturaNFC"
      :onLecturaCompletada="onLecturaCompletada" 
      @cerrar="cerrarlector"
    />
    <DialogMensaje
      v-model="bDialog"
      :titulo="sTituloMensaje"
      :texto="sMensaje"
      :textoBtn="sTextoBtnAceptar"
      :bShowCancelar="bBtnCancelDialgo"
      @onAceptar="fAceptar"
    >
      <template v-slot:icono>
        <v-icon large color="red"> mdi-alert</v-icon>
      </template>
    </DialogMensaje>
  </div>
  
</template>
<script>
import RondasRepository  from '@/views/control-actividades/rondas/RondasRepository.js'
import HeaderVista from '@/components/HeaderVista.vue';
import PuntosRealizados from '@/components/control-actividades/rondas/PuntosRealizados.vue';
import InformacionPunto from '@/components/control-actividades/rondas/InformacionPunto.vue';
import PuntoItemCard from '@/components/PuntoItemCard.vue';
import LecturaQR from '@/components/LecturaQR.vue' 
import LecturaNFC from '@/components/LecturaNFC.vue' 
import DialogMensaje from '@/components/DialogMensaje.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PuntosPorRonda',
  components: {
    HeaderVista,
      PuntosRealizados,
      InformacionPunto,
      PuntoItemCard,
      LecturaQR,
      LecturaNFC,
      DialogMensaje
  },
  data() {
    return {
      sIdRondaCalendario : '0',
      sTitulo: '',
      nPuntosRealizados: 0,
      nPuntosTotales: 0,
      sTipoHorario: 'Flexible',
      oRonda:{},
      bRondaCompletada: false,
      isLecturaQR: false,
      isLecturaNFC:false,
      nTecnologia: 0, // 0 - NFC, 1 - QR 
      bDialog: false,
      bBtnCancelDialgo: false,
      sTituloMensaje: '',
      sMensaje: '',
      sTextoBtnAceptar: '',
      fAceptar:()=>{},
      puntos: [],
    };
  },
  mounted() {
    this.sIdRondaCalendario = this.$route.params.idRonda;
    this.onConsultarPuntos()
  },
  methods: {
    onConsultarPuntos(){
      this.oRonda = RondasRepository.onGetRondaById(this.sIdRondaCalendario)
      if(this.oRonda != undefined){
        this.sTitulo = this.oRonda.nombre
        this.sTipoHorario = this.oRonda.hora
        this.bRondaCompletada = this.oRonda.finalizado
      }
      this.puntos = RondasRepository.onGetPuntosByRonda(this.sIdRondaCalendario)
      this.nPuntosRealizados = this.oRonda.nPuntosMarcados
      this.nPuntosTotales = this.oRonda.nPuntosTotales
    },
    escapeInternalQuotes(jsonString) {
      // Reemplazar las comillas internas en las cadenas JSON por comillas escapadas
      return jsonString.replace(/"(?![^{}]*[:])(?=[^{}]*{)/g, '\\"');
    },
    onMarcarPunto(){
      if(this.getbMarcacionQr){
        //this.onLecturaCompletada("PuntoMarcacion|5|290|2024-09-02 14:42:12")
        this.onClickLecturaQR()
      }else{
        //this.onLecturaCompletada("2652388298")
        this.onClickLecturaNFC()
      }
    },
    onFinalizarRonda(){
      this.$router.push(`/FinalizarRondaIncompleta/${this.sIdRondaCalendario}`);
    },
    onClickLecturaQR() {
      this.isLecturaQR = true
    },
    onClickLecturaNFC() {
      this.isLecturaNFC = true
    },
    onLecturaCompletada(sCodigo){
      this.cerrarlector()
      let oPunto = RondasRepository.onGetPuntoByCodigo(this.sIdRondaCalendario, sCodigo)
      if(oPunto !=undefined){
        if(oPunto.aPreguntas.length>0){
          this.$router.push(`/ListaVerificacionPunto/${this.sIdRondaCalendario}/${sCodigo}`);
        }else{
          this.$router.push(`/ReporteMarcacion/${this.sIdRondaCalendario}/${sCodigo}`);
        }
      }else{
        this.bDialog = true
        this.bBtnCancelDialgo = false
        this.sTituloMensaje = 'Punto no asignado'
        this.sMensaje = 'El punto leido no se encuentra asignado a esta ronda.'
        this.sTextoBtnAceptar = this.$STR.btnAceptar
        this.fAceptar = () =>{}
      }
    },
    cerrarlector(){
      this.isLecturaQR = false
      this.isLecturaNFC = false
    },
    onClickFinalizarRonda(){
      this.bDialog = true
      this.bBtnCancelDialgo = true
      this.sTituloMensaje = this.$STR.ttlRondaIncompleta
      this.sMensaje = this.$STR.msgFinalizarRondaIncompleta
      this.sTextoBtnAceptar = this.$STR.btnConfirmaContinuar
      this.fAceptar = this.onFinalizarRonda
    }

  },
  computed: {
    ...mapGetters(['getbMenuBlock', 'getbMarcacionQr']),
    tipoDePuntosTest() {
      return typeof this.puntosTest;
    }
  }
}
</script>
<style lang="scss" scoped>
.contenedor-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

</style>  