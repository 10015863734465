<template>
<div>
  <div v-for="(item, index) in resdatos" :key="index">
    <span class="ntitlesdark--text">{{item.Titulo}}</span>
    <!-- IMAGEN INDIVUDUAL -->  
    <v-row v-if="item.TipoPregunta == 15" xs12 justify="center" class="under-sec" >
      <v-col cols="4" class="py-1">
        <button color="primary" class="btn-img large" @click="ampliar(item.Respuesta.imagen)">
          <img :src="item.Respuesta.imagen" width="100%">
        </button>
      </v-col>
    </v-row>
    <!-- CORREO -->
     <v-row v-if="item.TipoPregunta == 14" xs12 class="cont-resp-texto" >
      <v-list class="my-0">
        <v-list-item
          v-for="(correo, key) in item.Detalle"
          :key="key"
          class="mb-n7 mt-n4">
          <v-list-item-title v-text="correo.Correo" class="my-0"></v-list-item-title>
        </v-list-item>
      </v-list>  
    </v-row> 
    <!-- FIRMA-->
    <v-row v-if="item.TipoPregunta == 12" xs12 justify="center" class="under-sec" >
      <v-col cols="4" v-for="(itemftimg, idx) in item.Detalle" :key="'itemftimg'+idx" class="py-1">
        <button color="primary" class="btn-img large" @click="ampliar(itemftimg.Base64, itemftimg.Asistente)">
          <img :src="itemftimg.Base64">
        </button>
        <div class="cont-resp-texto">
          <span>{{itemftimg.Asistente}}</span>
        </div>
      </v-col>
    </v-row>
    <!-- CORREO -->
     <v-row v-if="item.TipoPregunta == 4" xs12 class="cont-resp-texto" >
      <v-list class="my-0">
        <v-list-item
          v-for="(select, key) in item.Detalle"
          :key="key"
          class="mb-n7 mt-n4">
          <v-list-item-title v-text="select.Respuesta" class="my-0"></v-list-item-title>
        </v-list-item>
      </v-list>  
    </v-row> 
    <!-- GALERIA DE FOTOS -->
    <v-row v-if="item.TipoPregunta == 3" xs12 justify="center" class="under-sec" >
      <v-col cols="4" v-for="(itemfotos, idx) in item.Detalle" :key="'idx'+idx" class="py-1">
        <button color="primary" class="btn-img large" @click="ampliar(itemfotos)">
          <img :src="itemfotos.Base64">
        </button>
      </v-col>
    </v-row>      
    <!-- CheckBoox -->
    <v-row v-if="item.TipoPregunta == 5" xs12 class="cont-resp-texto" >
      <v-icon  v-if="item.ValorRespuesta == '1'" medium color="green darken-2">mdi-checkbox-marked-outline</v-icon>
      <v-icon  v-else medium color="green darken-2">mdi-checkbox-blank-outline</v-icon>
    </v-row>  
    <!-- TEXTO EN GENERAL -->
    <v-row v-if="item.TipoPregunta == 1 || 2 || 7 || 9 || 8 " class="cont-resp-texto">
      <v-col cols="12" >
        <span>{{item.TextoRespuesta}}</span>
      </v-col>
    </v-row> 
  </div>

  <!-- MODAL PARA AMPLIAR FOTOS -->
  <AmpliarFoto 
    :fotoampliada="ftampliada"
    :textoampliado="txtampliado"
    v-model="showFoto" >
  </AmpliarFoto>

</div>
</template>

<script>
import AmpliarFoto from '@/components/dinamicos/compAuxiliares/AmpliarFoto.vue'

  export default {
    name:"RespuestaCampos",
    components:{
      AmpliarFoto,
    },
    props: {
      resdatos: {
        type: Object,
        default: ()=> {}
      },
    },
    data() {
      return {
          showFoto: false,
          ftampliada: null,
          txtampliado: null
      }
    },
    methods: {
      ampliar(val, infotext){
        this.showFoto = true
        this.ftampliada = val
        this.txtampliado = infotext
      },
    },
    
  }
</script>

<style lang="scss" scoped>
// corrige un margin negativo que está declarado en algún lugar del universo de vuetify 
.row {
  margin: 0!important;
}
.cont-resp-texto{
  font-size: 0.8em;
}
.under-sec{
  padding: 2px!important;
}
.btn-img{
  border-radius: 10px;
  img{
    width: 100%;
  }
}
</style>