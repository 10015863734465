<template>
    <div>
      <v-card class="no-shadow-card d-flex">
        <div class="mx-2 d-flex">
          <slot name="icono"></slot>
        </div>
        <v-col no-gutters class="pa-0">
          <v-card-title class="pa-2">
              <span :class="sColor">
                   {{ sTitulo }}
              </span>
          </v-card-title>
          <v-card-subtitle class="pa-2">{{ sSubTitulo }}</v-card-subtitle>
        </v-col>
      </v-card>
    </div>
</template>
<script>
    export default {
        name: 'TituloVista',
        props: {
          sTitulo: {
              type: String,
              required: true
          },
          sSubTitulo: {
            type: String,
            default:''
          },
          sColor: {
            type: String,
            default: "titlesdark--text"
          }
        }
    }
</script>
<style lang="scss" scoped>
    .no-shadow-card {
        box-shadow: none !important;
    }
</style>
  