import store from '@/store/index.js'
import APPutils from '@/utils/APPutils.js'

export default {

  TipoActividades:{
    Rondas:1
  },

  onGetDataTabla(tablaName) {
    let getdataBase = store.state.dataBase.dataBase
    const infoTabla = getdataBase.find(tabla => tabla.tablaName == tablaName)
    return infoTabla == undefined ? [] : infoTabla.info
  },

  onGetActividadesByTipo(nTipo){
    const aActividades = this.onGetDataTabla('CA_Actividades_Calendario')
    const aData = aActividades.filter(oActividad => oActividad.detalle.nTipo == nTipo)
    return aData == undefined ? [] : aData
  },

  onGetActividadesByTipoConFecha(nTipo, sFechaProgramada){
    const aActividades = this.onGetDataTabla('CA_Actividades_Calendario')
    
    const aData = aActividades
      .filter(oActividad => (oActividad.detalle.nTipo == nTipo && 
                            oActividad.sFechaProgramada == sFechaProgramada
      ))
    return aData == undefined ? [] : aData
  },

  onRegistrarRespuestaActivdad(sIdActividadCalendario, aDetalleActividad, bIsApp=false){
    let getdataBase = store.state.dataBase.dataBase
    const indexTabla = getdataBase.findIndex(tabla => tabla.tablaName == 'CA_Actividades_Calendario')
    if(indexTabla < 0) return false

    const indexActividad = store.state.dataBase.dataBase[indexTabla].info.findIndex(oActividad => oActividad.idActividadCalendario == sIdActividadCalendario)
    if(indexActividad < 0) return false
    store.state.dataBase.dataBase[indexTabla].info[indexActividad].detalle = aDetalleActividad
    if(bIsApp){
      const sQuery = `UPDATE CA_Actividades_Calendario SET detalle = '${JSON.stringify(aDetalleActividad)}' WHERE idActividadCalendario='${sIdActividadCalendario}'`
      APPutils.onEjecutarQuery("DatabaseSeif", sQuery).then(result => {
      }).catch(error => {
          console.error("Error:", error);
      });
    }

    return true
  },



}