<template>
    <v-snackbar
      v-model="getsnackbar"
      color="#004AAD"
      :multi-line="true"
      top 
      :timeout="timeout">
      <p><strong>{{ gettituloNotificacion }}</strong></p>
      <p>{{ getmensajeNotificacion }}</p> 
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          color="blue"
          text
          @click="$store.dispatch('firebasenotificaciones/ShowNotificacion',false)">
          Close
        </v-btn>
      </template>  
    </v-snackbar>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name : "FirebaseNotificacion",
  data() {
    return {
      text    : '',
      timeout : -1, 
    }
  },
  computed: {
    ...mapGetters('firebasenotificaciones',['getsnackbar', 'gettituloNotificacion', 'getmensajeNotificacion'])
  },
}
</script>