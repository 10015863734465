<template>
  <div class="margen-form">
    <v-container>
      <v-col class="pa-0 mb-2" cols="12">
        <HeaderVista :sTitulo="$STR.ttReporteNovevdadIncidente" :sSubTitulo="`Sede : ${sNombreSede}`" sColor="black--text">
          <template v-slot:icono>
            <v-icon large> mdi-file-document-outline </v-icon>
          </template>
        </HeaderVista>

        <v-card
          width="100%"
          class="pa-5 mt-5 card-rounded over-hidden"
        >
          <v-col class="pa-0 mb-2 justify-center" cols="12">
            <v-row justify="start">
              <span class="text-size-2 ml-2 mb-5">Informacion General</span>
            </v-row>
            <ContenedorCampos class="mt-5" :titulo="$STR.lblPunto" :Obligatorio="1"  />
             <v-select
                label="Seleccione una opción"
                v-model="nIdPunto"
                :items="aPuntos"
                item-text="DatoMostrar"
                item-value="ID"
                dense
                outlined>
              </v-select>
          </v-col>
        </v-card>
      </v-col>


      <v-card
        width="100%"
        class="pa-5 mt-5 card-rounded over-hidden"
      >
        <v-col class="pa-0 mb-2 justify-center" cols="12">
          <v-row justify="start">
            <span class="text-size-2 ml-2 mb-5">{{ $STR.lblQueDeseaReportar }}</span>
          </v-row>
          <v-row justify="center">
            <ToggleButton
              v-model="tipoReporte"
              :aData="aValores"
              >
            </ToggleButton>
          </v-row>
        </v-col>
      </v-card>


      <v-card
        width="100%"
        class="pa-5 mt-5 card-rounded over-hidden"
      >
        <span class="text-size-2 ml-2 mb-5">Detalles</span>

        <v-col class="pa-0 mb-2 mt-5 justify-center" cols="12">
          <ContenedorCampos class="mt-5" :titulo="$STR.lblTipificacionGeneral" :Obligatorio="1"  />
          <v-select
            label="Seleccione una opción"
            v-model="TipificacionGeneral"
            :items="aTipificacionGeneral"
            item-text="DatoMostrar"
            item-value="ID"
            dense
            outlined>
          </v-select>
        </v-col>
        <v-col class="pa-0 mb-2 justify-center" cols="12">
          <ContenedorCampos class="mt-5" :titulo="$STR.lblTipificacionEspecifica" :Obligatorio="1"  />
          <v-select
            label="Seleccione una opción"
            v-model="TipificacionEspecifica"
            :items="aDatosTipificacionEspecifica"
            item-text="DatoMostrar"
            item-value="ID"
            dense
            outlined>
          </v-select>
        </v-col>

        <ContenedorCampos class="mt-5" :titulo="$STR.cmpObs"  />
        <v-textarea
          v-model="sObservacion"
          :placeholder="$STR.cmpObs"
          rows="3"
          row-height="10"
          outlined
          required
        />

        <v-col no-gutters class="pa-0 mt-3"> 
          <Fotos
            :key="oKeyFoto"
            v-model="aFotos"
            :sTitulo='$STR.lblAnexos'
            :nCantidadFotos="2"
          />
        </v-col>
      </v-card>

      <v-sheet
        class="text-center contenedor-bottom mt-5"
        >
          <v-col class="pa-0">
            <v-row no-gutters justify="center" align="center">
              <v-btn block @click="onEnviar" large dark color="primary" class="btn-primary btn-rounded">
               {{ $STR.btnEnviar }}
              </v-btn>
            </v-row> 
            <v-row no-gutters  justify="center" align="center" class="mt-2">
              <v-btn block @click="onCancelar" large dark color="error" outlined class="btn-primary btn-rounded">
                {{ $STR.btnCancelar }}
              </v-btn>
            </v-row>  
          </v-col>
      </v-sheet>

    </v-container>
    <DialogMensaje
      v-model="bDialogCamposObligatorios"
      :titulo="sTituloMensaje"
      :texto="sMensajeError"
    >
      <template v-slot:icono>
        <v-icon large color="red"> mdi-alert</v-icon>
      </template>
    </DialogMensaje>
  </div>
  
</template>
<script>
import RondasRepository  from '@/views/control-actividades/rondas/RondasRepository.js'
import HeaderVista from '@/components/HeaderVista.vue';

import { mapGetters } from 'vuex'
import EditTextAlfanumerico from '@/components/dinamicos/campos/EditTextAlfanumerico.vue';
import ToggleButton from '@/components/ToggleButton.vue'
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue';
import Fotos from '@/components/Fotos.vue'
import DialogMensaje from '@/components/DialogMensaje.vue'
import APPutils from '@/utils/APPutils.js'
import HoraFecha from "@/utils/Hora_Fecha.js"
import GPSLocation from '@/utils/GPSlocation.js'
import Select from '@/components/dinamicos/campos/Select.vue'

export default {
  name: 'ReporteNovedadIncicidente',
  components: {
    HeaderVista,
    EditTextAlfanumerico,
    ContenedorCampos,
    Fotos,
    DialogMensaje,
    ToggleButton,
    Select
  },
  data() {
    return {
      nIdPunto : 0,
      idSedeBase : 0,
      sNombreSede: '',
      aPuntos:[],
      sTitulo: '',
      sSubTitulo: '',
      sRiesgo: '',
      tipoReporte: "0",
      TipificacionGeneral: 0,
      TipificacionEspecifica: 0,
      aTipificacionGeneral: [],
      aTipificacionEspecifica: [],
      aValores: [
        {ID:"1", DatoMostrar:"Novedad"}, 
        {ID:"2", DatoMostrar:"Incidente"}
      ],
      sObservacion: '',
      oKeyFoto: 1,
      aFotos:[],
      oLocation : {},
      disabledGuardar : false,
      bErrorPermisos : false ,
      bDialogCamposObligatorios:false,
      sTituloMensaje:"",
      sMensajeError:"",
      sFormulario: "Reporte Novedad Incidente",
      
    };
  },
  async mounted() {
    // Se llama despues de obtener los parametros de la url
    this.onCargarInformacion()

    try{
      this.oLocation = await GPSLocation.getCurrentPosition()
      this.bErrorPermisos = false
    }catch(e){
      if(e.message=='User denied Geolocation'){
        this.bErrorPermisos = true
      }
      // Si hay error capturo el objeto segun la Doc https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
      APPutils.onGuardarLog("Error GeolocationPositionError: {code:"+e.code+", message:"+e.message+"}")
    } 
  },
  methods: {
    onCancelar(){
      this.$router.back()
    },
    async onEnviar(){
      if(!this.isFormularioValido()){
        const sParams = `imei=${this.getimei}&app=PWA`
        const aNombreFotos = [];
        this.aFotos.forEach( async foto => {
          aNombreFotos.push({ fotoNombre : foto.NombreFoto})
        })

        const sReporte = this.aValores.find(reporte => reporte.ID == this.tipoReporte)

        const oBody = {
          imei : this.getimei,
          app : "ReporteNovedadIncicidente",
          idPunto: this.nIdPunto,
          idSede: this.idSedeBase,
          gestion: sReporte.DatoMostrar,
          riesgo: this.sRiesgo,
          idTipificacionEspecifica : this.TipificacionEspecifica,
          idSubProyecto:0,
          observaciones : this.sObservacion,
          galeria : aNombreFotos,
          Latitud : this.oLocation.coords.latitude,
          Longitud : this.oLocation.coords.longitude,
          fecha : HoraFecha.getNow(),
        }
        const oResponse = await RondasRepository.onReporteMarcacion(sParams, oBody, this.aFotos, this.getisAPP)
        if(this.getisAPP) {
          if(!Object.prototype.hasOwnProperty.call(oResponse, 'nStatusCode')) {
            APPutils.enviarDataOffline(oBody, this.sFormulario)
          }else {
            if ((oResponse.nStatusCode == 200) || (oResponse.nStatusCode == 220)  ){
              APPutils.registro_OK()
            } else {
              APPutils.enviarDataOffline(oBody, this.sFormulario)
            }
          }
        } else {
          switch (oResponse.nStatusCode) {
            case 200: {
              this.$swal({
                timer: 1500,
                icon: `${oResponse.sStatus}`,
                title: `${oResponse.sMessage}`,
                showConfirmButton: false
              })
              break
            }
            case 220: {
                this.$swal({
                timer: 1500,
                icon: 'info',
                title: `${oResponse.sMessage} # ${oResponse.aData}`,
                showConfirmButton: false
              })
              break
            }
          }

          this.salir()
        }
      }else{
        this.sTituloMensaje="Campos requeridos"
        this.bDialogCamposObligatorios = true
      }
    },
    onCargarInformacion(){
      let sTablaPuntos = ""
      
      //Valido si es Seif o SM para cargar la info de la sede y saber el nombre la tabla puntos
      if(this.getsTipoApp == "1"){
        if(this.getnIDSedeBase > 0 && this.getaSedes.length > 0){
          this.idSedeBase = this.getnIDSedeBase
          let tablaSede = this.getdataBase.find( table => table.tablaName == "Sede")
          let sede = tablaSede.info.find(sede => sede.idSede == this.idSedeBase)
          this.sNombreSede = sede.Nombre_Sede
        }
        sTablaPuntos = "/puntosriesgos"
      }
      else{
        let tablaSede = this.getdataBase.find( table => table.tablaName == "sede")
        let sede = tablaSede.info.find(sede => sede.idsede == this.idSedeBase)
        this.sNombreSede = sede.Nombre
        sTablaPuntos = "/riesgos/puntos"
      }
      this.aPuntos =  RondasRepository.onGetAllPuntos(sTablaPuntos).map((punto) => {
        return {
          ID: punto.sIdPunto,
          DatoMostrar: punto.sNombrePunto
        }
      })

      let tabla = this.getdataBase.find( table => table.tablaName == "RiesgoGrupos")
      this.aTipificacionGeneral = tabla.info.map((objeto) => {
        return {
          ID: objeto.idRiesgoGrupos,
          DatoMostrar: objeto.RiesgoGrupos
        }
      })
      tabla = this.getdataBase.find( table => table.tablaName == "RiesgoTipificacion")
      this.aTipificacionEspecifica = tabla.info.map((objeto) => {
        return {
          ID: objeto.idRiesgoTipificacion,
          DatoMostrar: objeto.nombreTipificacioncol,
          Filtro: objeto.GrupoTipificacion
        }
      })
    },
    isFormularioValido(){
      this.sMensaje = ''
      let error = false

      if(this.tipoReporte=="0"){
        this.sMensajeError += '- Tipo de Reporte no diligenciado.'
        error = true
      }

      if(this.nIdPunto==0){
        this.sMensajeError += '- Punto no diligenciado.'
        error = true
      }

      return error
    }, 
    salir () {
      this.$router.push(`/Home`);
    },
  },
  computed: {
    aDatosTipificacionEspecifica(){
      if((this.TipificacionGeneral > 0) && (this.TipificacionGeneral !== undefined) && (this.TipificacionGeneral !== null) ){
        return this.aTipificacionEspecifica.filter( elemento => elemento.Filtro == this.TipificacionGeneral ) 
      } 
      return this.aTipificacionEspecifica
    },
    ...mapGetters(['getbMenuBlock','getimei', 'getisAPP', 'getaSedes', 'getnIDSedeBase', 'getsTipoApp']),
    ...mapGetters('dataBase',['getdataBase']),
  }
}
</script>
<style lang="scss" scoped>
.contenedor-bottom {
  background-color: unset;
  bottom: 0;
  width: 100%;
  margin-bottom: 50px;
}

</style>  