<template>
   <div class="d-flex justify-center mb-2 color-gray-light">
     <div class="mr-1 text-medium"> {{ $STR.lblFecha}} </div>
     <div class="mr-2"> {{ FechaActual }} </div>
     <div class="mr-1 text-medium"> {{ $STR.lblHora }}</div>
     <div> {{ HoraActual }} </div>
  </div>
</template>

<script>

export default {
  name: 'FechaSeccion',
  components: {  },
  data () {
		return {
      FechaFull: '',
      FechaActual: '',
      HoraActual: ''
    }
	},
	mounted() {
    const dFecha = new Date();
		//console.log(dFecha)
    this.FechaActual = dFecha.getFullYear()+'/'+this.FormatFecha(dFecha.getMonth(),'Mes') + '/' + this.FormatFecha(dFecha.getDate())
    this.HoraActual = this.FormatFecha(dFecha.getHours()) + ':' + this.FormatFecha(dFecha.getMinutes()) + ':' + this.FormatFecha(dFecha.getSeconds())
    this.FechaFull = this.FechaActual + ' ' + this.HoraActual
  },
  methods: {
    FormatFecha(nNumero, sTipo = ''){
      if(sTipo=='Mes') nNumero = nNumero + 1

      if(nNumero < 10){
        return '0' +nNumero
      }else{
        return nNumero
      }
    }
  },
	watch: {
	},

}
</script>