export default {
  getNow() {
    const hoy = new Date
    return `${hoy.getFullYear()}-${this.onConvertirFecha(hoy.getMonth() + 1)}-${this.onConvertirFecha(hoy.getDate())} ${this.onConvertirFecha(hoy.getHours())}:${this.onConvertirFecha(hoy.getMinutes())}:${this.onConvertirFecha(hoy.getSeconds())}`         
  },
  getNowFile() {
    const hoy = new Date
    return `${hoy.getFullYear()}${hoy.getMonth()<10?'0':''}${(hoy.getMonth() + 1)}${hoy.getDate()<10?'0':''}${hoy.getDate()}${hoy.getHours()<10?'0':''}${hoy.getHours()}${hoy.getMinutes()<10?'0':''}${hoy.getMinutes()}${hoy.getSeconds()<10?'0':''}${hoy.getSeconds()}${hoy.getMilliseconds()}`         
  },
  getDia() {
    const hoy = new Date
    return `${hoy.getFullYear()}-${this.onConvertirFecha(hoy.getMonth() + 1)}-${this.onConvertirFecha(hoy.getDate())}`         
  },
  getHora() {
    const hoy = new Date
    return `${this.onConvertirFecha(hoy.getHours())}:${this.onConvertirFecha(hoy.getMinutes())}:${this.onConvertirFecha(hoy.getSeconds())}`         
  },
  onConvertirFecha(nNumero){
    return (nNumero<=9) ? '0' + nNumero : nNumero
  },
  onSumarMinutosHora(sHora, nMinutos){
    const dFechaFin = new Date(0,0,0,sHora.split(":")[0],sHora.split(":")[1],sHora.split(":")[2],0)
    dFechaFin.setMinutes(dFechaFin.getMinutes()  +nMinutos)
    return `${this.onConvertirFecha(dFechaFin.getHours())}:${this.onConvertirFecha(dFechaFin.getMinutes())}:${this.onConvertirFecha(dFechaFin.getSeconds())}`         
  },
  onGetDiferenciaEntreFechas(sFecha1, sFecha2){
    console.log("sFecha1",sFecha1, "sFecha2", sFecha2);
    const fechaInicial= new Date(sFecha1);
    const fechaFinal= new Date(sFecha2);
    const diferenciaMilisegundos = fechaFinal - fechaInicial;
    const diferenciaRealMinutos = parseInt(diferenciaMilisegundos / (1000 * 60));
    return diferenciaRealMinutos
  },
  getDateTimeBy(nLongMilliseg) {
    const date = new Date(nLongMilliseg); // Convierte el timestamp a un objeto Date
    let formattedDate = date.getFullYear() + "-" +
      String(date.getMonth() + 1).padStart(2, '0') + "-" +
      String(date.getDate()).padStart(2, '0') + " " +
      String(date.getHours()).padStart(2, '0') + ":" +
      String(date.getMinutes()).padStart(2, '0') + ":" +
      String(date.getSeconds()).padStart(2, '0');
    return formattedDate        
  },

  onCompararFechaHora(sFechaHora1, sFechaHora2) {
    // Devuelve un número negativo, lo que indica que sFechaHora2 es menor que sFechaHora1
    // Devuelve un número positivo, indicando que sFechaHora2 es mayor que sFechaHora1
    // Devuelve 0, indicando que son iguales
    const fechaInicial= new Date(sFechaHora1);
    const fechaFinal= new Date(sFechaHora2);
    //console.log("onCompararFechaHora",sFechaHora1, sFechaHora2);
    return fechaFinal - fechaInicial;
  }
}