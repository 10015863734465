<template>
  <div>
    <span v-html="bTextMas ? sTexto : sTexto.slice(0,100)"> 
    </span>
    <span v-if="sTexto.length > 99">
      {{ bTextMas ? '' : '...'}}
      <v-btn x-small text @click="bTextMas = !bTextMas">
        <span class="boton-mas">
          {{ bTextMas ? 'Leer menos' : 'Leer más'}}
        </span>
      </v-btn>
    </span>
  </div>
</template>
<script>
export default {
  name:'verMasTexto',
  props:{
    sTexto:{
      type: String,
      default: 'Sin Información'
    }
  },
  data() {
    return {
      bTextMas: false
    }
  },
}
</script>
<style lang="scss" scoped>
.boton-mas{
  color: blue;
}
</style>