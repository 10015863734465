<template>
  <div>
    <v-btn
      color="primary" class="btn-rounded px-3 py-1" 
      medium dark depressed
      block
      @click="Camera">
       FOTO  <v-icon dark right>mdi-camera</v-icon>
    </v-btn>   
    <v-dialog
      v-model="showCamera"
      scrollable 
      persistent 
      :overlay="false"
      max-width="500px">
      <v-card >
        <v-toolbar dark color="primary" dense >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="showCamera = false" class="btn-close">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <video 
          v-show="!isFoto" 
          ref="video" 
          :srcObject.prop="stream" 
          autoplay 
          playsinline 
          class="pa-0 ma-0"
          width="100%"
          height="100%"
          >
        </video>
        <canvas v-show="isFoto" ref="canvas" :width="anchoFoto" :height="altoFoto"></canvas>
        <v-card-actions class="justify-center">
          <v-btn fab dark small @click="tomarFoto" >      
            <v-icon dark>mdi-camera</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
  </div>    
</template>

<script>
/*  para entender como utilizar srcObjetc https://forum.vuejs.org/t/binding-to-srcobject-attribute/39323 */

// https://github.com/Donaldcwl/browser-image-compression
//https://developer.mozilla.org/es/docs/Web/API/MediaDevices/getUserMedia

import HoraFecha from '@/utils/Hora_Fecha.js'
import isMovil from  '@/utils/isMovil.js'


export default {
  name:"camer",
  data() {
    return {
      showCamera : false,
      stream : null,
      isFoto : false,
      anchoFoto : 500,
      altoFoto  : 375,

    }
  },
  props: {
      nCantidadFotos: {
        type: Number,
        default: 100
      },
      nFotosTomadas: {
        type: Number,
        default: 0
      },
    },
  async mounted() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ 
            video: { width: {max: 1280} , height: {max:720}, facingMode: (isMovil.validate() ?  "environment" : "user" ) },
            audio: false 
          })
        this.stream = stream   // Pasamos el video derecho 
      } catch(e) {
        // console.error(e.message)
        alert(`Se presenta problemas con la camaras, error: ${e} `)
      }
  },
  methods: {
    Camera() {
      this.showCamera = true
    },
    cancelar() {
      this.showCamera = false
      this.isFoto = false
    },
    async tomarFoto() {
      if(this.nFotosTomadas < this.nCantidadFotos){
        const context = this.$refs.canvas.getContext('2d')
        context.drawImage(this.$refs.video, 0, 0, this.anchoFoto ,this.altoFoto)

        this.$emit('fotoCapture', {NombreFoto: `${HoraFecha.getNowFile()}.png` , Base64: this.$refs.canvas.toDataURL("image/png")})

        this.cancelar()
      }else{
        this.$store.dispatch('notificaciones/SetMensaje',this.$STR.msgMaxFotos.replace('XXX',this.nCantidadFotos) )
        this.$store.dispatch('notificaciones/ShowNotificacion',true) 
      }
      
    },
  },
}
</script>

<style lang="scss" scoped>
// .btn-close{
//   border:solid blue;
// }

</style>