<template>
<ContenedorCampos :titulo="titulo" :isObligatorio="Obligatorio" :error="error">
  <v-select
    label="Seleccione sus opciones"
    v-model="aValue"
    :items="aOptions"
    item-text="DatoMostrar"
    item-value="ID"
    @change="Change"
    multiple
    autocomplete
    dense
    outlined>
  </v-select>
</ContenedorCampos>
</template>
<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'
import ValidarBD from '@/utils/ValidarBD.js'


export default {
  name: 'MultiSelect',
  mixins: [ DinamicosMixin ],
  components: {
    ContenedorCampos,
  },
  data () {
    return {
      aValue : [],
    }
  },
  mounted () {
    // Debo obtener los valores a mostra los cuales se guardan en la tabla local storage que vienen en Valor bajo la estructura
    // DatoMostrar: lo que se muestar -    Filtro: para filtrar   -   ID: mi ID   -   Indice: NO usado

    this.aOptions =  ValidarBD.getInfoTabla(this.aData)
  },
  methods: {
    Change(){
      this.selectValue = this.aValue
    },
  },
  computed: {
    
  },

}
</script>

<style lang="scss" scoped>

</style>
