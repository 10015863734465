<template>
<ContenedorCampos :titulo="titulo" :isObligatorio="Obligatorio" :error="error">
  <v-autocomplete
    label="Seleccione una opción"
    v-model="selectValue"
    :items="aDatoMostrar"
    item-text="DatoMostrar"
    item-value="ID"
    @change="Change"
    autocomplete
    clearable
    dense
    outlined>
  </v-autocomplete>
</ContenedorCampos>
</template>
<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'
import ValidarBD from '@/utils/ValidarBD.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Select',
  mixins: [ DinamicosMixin ],  
  components: {
    ContenedorCampos,
  },
  data () {
    return {
    }
  },
  async mounted () {
    // Debo obtener los valores a mostra los cuales se guardan en la tabla local storage que vienen en Valor bajo la estructura
    // DatoMostrar: lo que se muestar -    Filtro: para filtrar   -   ID: mi ID   -   Indice: NO usado

    this.aOptions =  ValidarBD.getInfoTabla(this.aData)

  },
  methods: {
    Change(){

    },
  },
  computed: {
    aDatoMostrar(){

      // valido si en getaModificaA existe algo del id de este select para filtar sino regresa la variable limpia.
      if((this.getnModificaA > 0) && (this.getaModificaA[this.id] !== undefined) && (this.getaModificaA[this.id] !== null) && (this.getaModificaA[this.id] !== "") ){
        return this.aOptions.filter( elemento => elemento.Filtro == this.getaModificaA[this.id] ) 
      } 

      return this.aOptions
    },

    ...mapGetters('dinamicos',['getaModificaA', 'getnModificaA'])
  },

}
</script>

<style lang="scss" scoped>

</style>
