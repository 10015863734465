<template>
  <div class="container text-center class_Grupo my-4" >
    <span my-10>{{titulo}}</span>
  </div>  
</template>

<script>
import DinamicosMixin from '@/mixins/dinamicosMixin.js'

export default {
  name: 'Grupo',
  mixins: [ DinamicosMixin ],
  components: {
    // ContenedorCampos,
  },
  data () {
    return {

    }
  },
  mounted () {
  },
  methods: {

  },
  computed: {

  },

}
</script>

<style lang="scss" scoped>
 .class_Grupo {
   background-color: #054278;
   color: white;
   border-radius: 5px;
 }
</style>
