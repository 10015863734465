import TablasDescarga from "@/components/descargadb/TablasDescarga.js"
import TablasDescargaSeif from "@/components/descargadb/TablasDescargaSeif.js"
import store from '@/store/index.js'
import axiosServices from "@/services/axiosServices.js"
import Hora_Fecha from "@/utils/Hora_Fecha.js"


let aTablasDescarga = (store.state.sTipoApp == "0") ? TablasDescargaSeif : TablasDescarga
let aTablasPemisos = []
// Tocar recargar en cada funcion por que se pueden cargar vacias
let getaPermisos = store.state.aPermisos
let getaActualizaciones = store.state.aActualizaciones
let getdataBase = store.state.dataBase.dataBase


export default {

  getRequiereActualizarDB() {
    getaPermisos = store.state.aPermisos
    getaActualizaciones = store.state.aActualizaciones
    getdataBase = store.state.dataBase.dataBase
    let isActualizarDB = false

    // console.log('getaActualizaciones', getaActualizaciones)
    // console.log('getaPermisos',getaPermisos)
    // console.log('aTablasDescarga', aTablasDescarga)

    let index_tblastActualizacion = this.getTablas('lastActualizacion')

    if (index_tblastActualizacion < 0) {
      // console.log('ActualizarBD por primera vez')
      isActualizarDB = true

    } else {
      // si existe la recorro aActualizaciones y comparo las fechas marcando la que no esten actualizado
      let tb_lastActualizacion = getdataBase[index_tblastActualizacion].info

      getaActualizaciones.forEach(actualizacion => {


        const aux_registro = tb_lastActualizacion.findIndex(elemento => elemento.Nombre_Tabla == actualizacion.Nombre_Tabla)
        if(aux_registro < 0) return;
        // con el actualizacion.Nombre_Tabla debo obtener el ID del modulo que esta en TablasDescarga.
        // Con el id Obtenido valido si esta en getaPermisos 
        // Si esta debe tener permiso = 1 si cumple todo sigo con las demas validacion para confirmar actualizacion
        // si el ID del Modulo es 0 sigo de una con las actualizaciones.
        // si el id del Moudlo no esta en get permisos retorno false que no requiere actualizar

        let idModulo = null
        for (const indexModulo in aTablasDescarga) {
          if (aTablasDescarga[indexModulo].find(elemento => elemento.equiMovil === actualizacion.Nombre_Tabla) !== undefined) idModulo = indexModulo
        }
        // console.log('idModulo',idModulo)

        let bTienePermiso = true
        if (idModulo == 0) {
          // console.log('idModulo 0, se valida actualizaciones')
          bTienePermiso = true
        } else {
          // console.log('idModulo != 0')

          for (const indexPermisos in getaPermisos) {
            if (parseInt(getaPermisos[indexPermisos].moduloid) == idModulo) {
              // console.log('idModulo dentro de los Permisos, se valida actualizaciones')
              bTienePermiso = true
            } else {
              // console.log('idModulo FUERA de los Permisos, NO valida actualizaciones')
              bTienePermiso = false
            }
          }
        }


        if (bTienePermiso && tb_lastActualizacion[aux_registro].fecha_actualizacion == '') {
          // console.log('ActualizarBD blanco', tb_lastActualizacion[aux_registro].Nombre_Tabla)
          isActualizarDB = true
          // return 
        }
        // console.log( 'fechas', new Date(tb_lastActualizacion[aux_registro].fecha_actualizacion), new Date(actualizacion.fecha_actualizacion), new Date(tb_lastActualizacion[aux_registro].fecha_actualizacion) < new Date(actualizacion.fecha_actualizacion))
        if (bTienePermiso && (new Date(tb_lastActualizacion[aux_registro].fecha_actualizacion) < new Date(actualizacion.fecha_actualizacion))) {
          // console.log('ActualizarBD diferencia fechas',tb_lastActualizacion[aux_registro].Nombre_Tabla)
          isActualizarDB = true
          // return 
        }

      })

    }
    // console.log('ActualizarBD', isActualizarDB)
    return isActualizarDB

  },
  getTablas(tablaName) {
    // esta funciona valida si la tabla existe o no dentro de la db storage
    return getdataBase.findIndex(table => table.tablaName == tablaName)
  },
  getBaseDatosDescarga() {
    getaPermisos = store.state.aPermisos
    getaActualizaciones = store.state.aActualizaciones
    getdataBase = store.state.dataBase.dataBase
    aTablasPemisos = []      // se inicializa en blanco por si se llama por segunda vez siempre inicie el proceso con esto en blanco


    // con los permisos que tengo en store valido que debo cargar y armo un arreglo el cual se guarda los ID de la Tablas a descargar
    let aux_aPermisos = []   // Almacena un array con los indices de los modulos  con permiso 1[ 0, 1,2,,22,..]

    // como el modulo id 0 es obligtorio lo contemplo deprimeras
    aux_aPermisos.push("0")

    getaPermisos.forEach(permiso => {
      if (permiso.permiso == "1")
        if (!aux_aPermisos.includes(permiso.moduloid))  // evito duplicados
          aux_aPermisos.push(permiso.moduloid)
    })

    // Validacion de los iconos y quitar duplicados por si los hay.
    // se tienen en la baseDedatos una tabla llamada actualización local y se valida contra Actualizaciones que llegaron en el login.

    let aux_aActualizaciones = []   // Arreglo de Objetos con detallando cuando se actualizo desde la APP [{ nombre_Tabla: , fecha_actualizacion: , icono: }]
    let index_tblastActualizacion = this.getTablas('lastActualizacion')
    // console.log('index_tblastActualizacion', index_tblastActualizacion)

    if (index_tblastActualizacion < 0) {
      // las creo en base a aActualizaciones
      getaActualizaciones.forEach(elemento => {
        aux_aActualizaciones.push(
          {
            Nombre_Tabla: elemento.Nombre_Tabla,
            fecha_actualizacion: '',
            icono: 0,   // Pendiente
            nRegistros: 0
          }
        )
      })
      //  console.log('Se crea la tabla')
      store.dispatch('dataBase/salvarTabla', { tablaName: 'lastActualizacion', info: aux_aActualizaciones })
      index_tblastActualizacion = this.getTablas('lastActualizacion')

    } else {
      // si existe la recorro aActualizaciones y comparo las fechas marcando la que no esten actualizado
      let tb_lastActualizacion = getdataBase[index_tblastActualizacion].info


      let aux_fecha_lastActualizacion = []

      getaActualizaciones.forEach(actualizacion => {
        const aux_registro = tb_lastActualizacion.findIndex(elemento => elemento.Nombre_Tabla == actualizacion.Nombre_Tabla)
        if(aux_registro > -1){
          if (tb_lastActualizacion[aux_registro].fecha_actualizacion == '') {
            //  console.log('retonro por blanco')
            aux_fecha_lastActualizacion.push(tb_lastActualizacion[aux_registro])
            return
          }
          if (new Date(tb_lastActualizacion[aux_registro].fecha_actualizacion) < new Date(actualizacion.fecha_actualizacion)) {
              console.log('retonro elemento por dif fechas', tb_lastActualizacion[aux_registro].fecha_actualizacion, actualizacion.fecha_actualizacion)
            store.dispatch('dataBase/deleteTabla', tb_lastActualizacion[aux_registro].Nombre_Tabla)
            tb_lastActualizacion[aux_registro].icono = 0
            tb_lastActualizacion[aux_registro].nRegistros = 0
            aux_fecha_lastActualizacion.push(tb_lastActualizacion[aux_registro])
            return
          }
          // como ya esta actualizado cambio el icono
          tb_lastActualizacion[aux_registro].icono = 2  // Descargado
          aux_fecha_lastActualizacion.push(tb_lastActualizacion[aux_registro])
        }else{
          // Si la tabla no existe en local quiere decir que se agrego nueva desde 
          // tabla Actualizaciones y en el alguno de los archivos TablasDescarga.js  o TablasDescargaSeif.js
          tb_lastActualizacion.push(
            {
              Nombre_Tabla: actualizacion.Nombre_Tabla,
              fecha_actualizacion: '',
              icono: 0,   // Pendiente
              nRegistros: 0
            }
          )
          //console.log("NUEVA", tb_lastActualizacion);
        }

      })
      // console.log('aux_fecha_lastActualizacion',aux_fecha_lastActualizacion)
      store.dispatch('dataBase/salvarTabla', { tablaName: 'lastActualizacion', info: tb_lastActualizacion })

    }

    //Se recorren los id de los modulos con permisos para extraer las tablas de cada uno

    aux_aPermisos.forEach(idTabla => {
      if (typeof aTablasDescarga[idTabla] != 'undefined') {
        aTablasPemisos.push(aTablasDescarga[idTabla])
      }
    })

    // Recorreo para poner los iconos que son y lo aplano
    let aux_aTablasPemisos = []
    let aux_tabla = getdataBase[index_tblastActualizacion].info
    aTablasPemisos.forEach(aTablas => {
      aTablas.forEach(tabla => {
        // busco el icono 
        const index = aux_tabla.findIndex(e => e.Nombre_Tabla == tabla.equiMovil)
        if (index < 0) {
          tabla.icono = 0
          tabla.nRegistros = 0
        } else {
          tabla.icono = aux_tabla[index].icono
          tabla.nRegistros = aux_tabla[index].nRegistros
        }
        aux_aTablasPemisos.push(tabla)
      })
    })

    return aux_aTablasPemisos

  },
  descargarTB(tablaName) {
    let URL = ''
    let sParams = ''
    if (tablaName.indexOf('/') < 0) {
      // por tablas 
      URL = '/tablas'
      sParams = `tabla=${tablaName}&idSede=${store.state.nIDSedeBase}&perfil=${store.state.nIdPerfil}`
    } else {
      // por endPoint
      URL = tablaName

      switch (tablaName) {
        case '/preguntasriesgos':
        case '/puntosriesgos':
          sParams = `imei=${store.state.imei}&tabla=Puntos_Riesgos&idSede=${store.state.nIDSedeBase}`
          break
        case '/espaciosparqueo':
          sParams = `imei=${store.state.imei}&idSede=${store.state.nIDSedeBase}`
          break
        default:
          sParams = `idSede=${store.state.nIDSedeBase}&perfil=${store.state.nIdPerfil}`
          break
      }
    }
    return axiosServices.getConfiguration(URL, true, sParams)
  },
  onDescargarTabla(tablaName, sTipoApp, nPagina = 1) {
    // Si el tipo de App es Seif, entonces usamos el enpoint de descarga de tablas para seif y la pagincacion.
    if(sTipoApp == "0"){
      let URL = ''
      let sParams = ''
      if (tablaName.indexOf('/') < 0) {
        URL = `/tablas/${tablaName}`
      }else{
        URL = tablaName
      }
      sParams = `idSede=${store.state.nIDSedeBase}&perfil=${store.state.nIdPerfil}&pagina=${nPagina}`
      return axiosServices.getConfiguration(URL, true, sParams)
    }else{
      // En caso, contrario descargamos tablas desde SeifModulos con la funcion existente.
      return this.descargarTB(tablaName)
    }
  },

  TB_actualizarFechadescarga(tablaName, nRegistros) {
    getdataBase = store.state.dataBase.dataBase

    // extratigo last Actualizaciones
    let tb_lastActualizacion = getdataBase[this.getTablas('lastActualizacion')].info
    // lo recorreo en busca de la Tabla
    tb_lastActualizacion.forEach((tabla, index) => {
      if (tabla.Nombre_Tabla == tablaName) {
        tb_lastActualizacion[index].fecha_actualizacion = Hora_Fecha.getNow()
        tb_lastActualizacion[index].nRegistros = nRegistros
        // console.log('salvando', tablaName , tb_lastActualizacion[index])
      }
    })
    store.dispatch('dataBase/salvarTabla', { tablaName: 'lastActualizacion', info: tb_lastActualizacion })
  },
  // Esta funcion se utiliza para sacar la infor de una tabla por el nombre
  getInfoTabla(tablaName) {
    const infoTabla = getdataBase.find(tabla => tabla.tablaName == tablaName)
    // console.log('infoTabla' , infoTabla)
    return infoTabla == undefined ? [] : infoTabla.info
  },


}