<template>
<ContenedorCampos :titulo="titulo" :isObligatorio="Obligatorio" :error="error">
  <v-text-field
    ref="txt_soloLetras"
    v-model="sSoloLetras"
    :rules="[rules.onlyText, rules.requerido]"
    outlined
    dense
    clearable
    autocomplete="off"
    @input="validarTecla"
  ></v-text-field>
</ContenedorCampos>
</template>
<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'

export default {
  name: 'EditTextTexto',
  mixins: [ DinamicosMixin ], 
  components: {
    ContenedorCampos,
  },  
  data () {
    return {
      sSoloLetras : '',
      rules: { 
        requerido: value => !!value || 'Indique un correo valido',
        onlyText : value  => {
              const pattern =  /^[a-zA-Z\s]*$/ 
              return pattern.test(value) ||  'Solo Letras'
            }
      }      
    }
  },
  mounted () {
  },
  methods: {
    QuitarNumero(e){
      if(e.charCode >= 48 && e.charCode <= 57 ){
         e.preventDefault()
      }
    },
    validarTecla(e){
      const numeros="0123456789";
      
      if(e != null){
        for(let i=0; i<e.length; i++){
            if (numeros.indexOf(e.charAt(i),0)!=-1){
              const valor = this.sSoloLetras.slice(0,i)
              this.$refs.txt_soloLetras.internalValue = valor
              this.selectValue = valor
            }

        }
        this.selectValue = this.sSoloLetras
      } else {
        this.selectValue = ''
      }
    }
  },

  computed: {


  },
  watch: {

  }

}
</script>

<style lang="scss" scoped>
</style>
