<template>
<v-container grid-list-xs container md-6 class="padding-50">
  <v-row no-gutters justify="center">
    <v-col cols="11" sm="8" md="6" lg="5">

      <v-card class="card-rounded my-2 pa-2 pt-0">
        <v-card-text
        class="text-medium color-primary-b text-size-1"
        >
          <v-row no-gutters>
            <v-col cols="2"></v-col>
            <v-col cols="8" class="pl-5 font-weight-bold primary--text">
              <v-row class="pb-1">
                <v-col class="d-flex">
                  Usar para:
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2" class="mr-1">
              <v-img
                src="/img/QR/police.png"
                contain
                ></v-img>
            </v-col>
            <v-col cols class="font-weight-bold pl-2  primary--text">
              
              <v-row no-gutters>
                <v-col cols="2">
                  <v-img
                  src="/img/QR/icon-nuevo-registro.svg"
                  contain
                  height="20"
                ></v-img>
                </v-col>
                <v-col class="d-flex">
                  Nuevo registro
                </v-col>
              </v-row>
              <hr class="my-1 ml-2">
              <v-row no-gutters>
                <v-col cols="2">
                  <v-img
                  src="/img/QR/icon-sin-qr.svg"
                  contain
                  height="20"
                  ></v-img>
                </v-col>
                <v-col class="d-flex">
                  Ingreso sin QR
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-card-text>
        <hr>
        <v-card-text
        class="text-medium color-primary-b justify-center text-size-1"
        >{{ $STR.ttlIdentificacionQR }}
        </v-card-text>
        <v-card-text>
          <v-row
            align="center"
            class="mx-0"
          >
            <v-text-field
              dense
              filled
              outlined
              rounded
              type="tel"
              v-model="nIdentificacion"
              :placeholder="$STR.lblIdentificacion"
            />
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="btn-primary margin-auto text-transform-initial text-semibold"
            color="primary"
            dark
            block
            @click="onClickRegistrar"
          >
            {{ $STR.btnRegistrar }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="card-rounded my-2 pa-2  pt-0">
        <v-card-text
        class="text-medium color-primary-b text-size-1"
        >
          <v-row no-gutters>
              <v-col cols="2" class="pr-1">
                <v-img
                src="/img/QR/police.png"
                  contain
                  ></v-img>
              </v-col>
              <v-col cols="10" class=" font-weight-bold primary--text pl-2 d-flex align-center">
                <v-row no-gutters>
                  <v-col cols="2">
                    <v-img
                    src="/img/QR/icon-solo-ingreso.svg"
                    contain
                    height="20"
                  ></v-img>
                  </v-col>
                  <v-col>
                  Usar solo para ingreso
                  </v-col>
                </v-row>

              </v-col>
          </v-row>
        </v-card-text>
        <hr>
        <v-card-text
        class="text-medium color-primary-b justify-center text-size-2"
        >{{ $STR.ttlLecturaQR }}</v-card-text>
        <v-img
          width="63" height="102"
          src="/img/QR/qr-scan-phone.gif"
          class="margin-auto"
          >
        </v-img>
        <v-card-actions>
          <v-btn
            @click="onClickLecturaQR"
            class="btn-primary margin-auto text-transform-initial text-semibold"
            color="primary"
            dark
            block
          >
            {{ $STR.btnLecturaQR }}
          <v-icon right>mdi-qrcode-scan</v-icon>
          </v-btn>
        
        </v-card-actions>
      </v-card>
      
    </v-col>
  </v-row>
  <LecturaQR 
    v-if="isLecturaQR" 
    :activo="isLecturaQR"
    :onLecturaCompletada="onCargarInfoQR" 
    @cerrarQR="cerrarlectorqr"
  />
  <DialogMensaje
    v-model="showDialog"
    :titulo="dialgomensaje.titulo"
    :texto="dialgomensaje.texto"
    :imagen="dialgomensaje.imagen"
  />
</v-container>
</template>

<script>
  import LecturaQR from '@/components/LecturaQR.vue' 
  import APPutils from '@/utils/APPutils.js'
  import DialogMensaje from '@/components/DialogMensaje.vue'

  export default {
    name:'DashboardLecturaQR',
    components: {
      LecturaQR,
      DialogMensaje
    },
    data() {
      return {
        nIdentificacion: '',
        isLecturaQR: false,
        showDialog: false,
        dialgomensaje: {
          titulo:'Bienvenido',
          texto:'Para registrar al visitante puedes leer el QR o ingresa el número de identificación',
          imagen:'/img/QR/police.png'
        }
      }
    },
    mounted () {
      if(this.getisAPP){
        this.$store.dispatch('setimei', APPutils.getIMEI() )
      }
    },
    methods: {
      onClickLecturaQR() {
        this.isLecturaQR = true
      },
      onClickRegistrar() {
        if(this.nIdentificacion.length > 0 && !isNaN(this.nIdentificacion)){
          this.$store.dispatch('setsQR', this.nIdentificacion)
          this.$router.replace({name: 'ElementosRegistro' , query: { "q": 'Cedula' } })
        }else{
          this.$store.dispatch('notificaciones/SetMensaje',this.$STR.msgNroIdentificacionNovalido )
          this.$store.dispatch('notificaciones/ShowNotificacion',true) 
        }
        
      },
      onCargarInfoQR(sCodigo){
        this.isLecturaQR = false
        this.$store.dispatch('setsQR', sCodigo)
        this.$router.replace({name: 'ElementosRegistro' , query: { "q": 'QR' } })
      },
      cerrarlectorqr(){
        this.isLecturaQR = false
      }
    },
    watch:{
    },
    computed: {
    },
  }
</script>

<style lang="scss" scoped>
.card-rounded{
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2)!important;
  text-align: center;
}
.margin-auto{
  margin: 0 auto;
}
// .btn-primary{
//   background: rgb(15,119,178);
//   background: linear-gradient(180deg, rgba(15,119,178,1) 0%, rgba(8,60,89,1) 100%);
// }

</style>