<template>
  <v-row no-gutters justify="center">
    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
      <span class="color-gray-light text-size-1">
        {{ $STR.stlInfoRegistro }}
      </span>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="pa-1">
      <v-card class="mx-auto card-rounded card-shadown">
        <v-form v-model="isFormularioValido">
          <v-container>
            <v-row>
              <v-col cols="12" md="12" xl="12" sm="12" lg="12">
                <span class="color-primary text-size-1 mb-1">
                  {{ $STR.cmpProcesoObligatorio }}
                </span>
                <v-select
                  v-model="oDatosRegistro.idAcceso"
                  item-text="nombreAcceso"
                  item-value="idAcceso"
                  :items="aDataAccesos"
                  :label="$STR.cmpAccesoObligatorio"
                />
              </v-col>
              <v-col cols="12" md="12" xl="12" sm="12" lg="12" class="text-center" >
                <span class="color-primary text-regular text-size-1">
                  {{ $STR.cmpProcesoObligatorio }}
                </span>
                <toggle-button
                  v-model="oDatosRegistro.TipoRegistro"
                  :aData="aOpcionesRegistro"
                  :bEntrada="isValidForm"
                />
                <v-row v-if="!isValidForm">
                  <v-col cols="12" class="text-center">
                    <p class="error--text mt-3 text-size-1">
                      <b>Tenemos un inconveniente!</b>
                      Hemos detectado que el elemento que intentas registrar  ya se encuentra dentro de las instalaciones
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6" xl="6" sm="6" lg="6">
                <v-textarea
                  v-model="oDatosRegistro.Observaciones"
                  :label="$STR.cmpObs"
                  :placeholder="$STR.lblObs"
                  rows="3"
                  row-height="10"
                />
              </v-col>
              <v-col cols="12" md="6" xl="6" sm="6" lg="6">
                <fotos
                  v-model="oDatosRegistro.Fotos"
                  :sTitulo="$STR.lblAnexos"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Fotos from "@/components/Fotos.vue";
import ToggleButton from "@/components/ToggleButton.vue";
import ReglasMixin from "@/mixins/ReglasMixin.js";
import ValidarBD from "@/utils/ValidarBD.js";

export default {
  name: "RegistroEntradaSalida",
  mixins: [ReglasMixin],
  components: {
    ToggleButton,
    Fotos,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isValidForm: { // indica si se puede continuar con el proceso de ingreso
      type: Boolean,
      default: true,
    },
    nValorElementos: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      isFormularioValido: false,
      oDatosRegistro: {
        idAcceso: "",
        TipoRegistros: "",
        Observaciones: "",
        Fotos: [],
      },
      aDataAccesos: [],
      aOpcionesRegistro: [
        {
          ID: "0",
          DatoMostrar: "Entrada",
          disabled: false
        },
        {
          ID: "1",
          DatoMostrar: "Salida",
          disabled: false
        },
      ]
    };
  },
  mounted() {
    this.oDatosRegistro = this.value;
    this.aDataAccesos = ValidarBD.getInfoTabla("Accesos");
  },
  methods: {
    onClickTomarFoto() {},
  },
  watch: {
    oDatosRegistro() {
      this.$emit("input", this.oDatosRegistro);
    },
    nValorElementos() {
      if(this.nValorElementos >= 0) {
        this.aOpcionesRegistro.forEach((item) => item.disabled = false)
        this.aOpcionesRegistro[this.nValorElementos].disabled = true        
      }
    }
  },
};
</script>
<style lang="scss" >
.v-textarea textarea {
  line-height: 1.4em;
}
</style>