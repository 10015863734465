<template>
<div >
  <span class="font-weight-medium" >{{sLabel}}</span>
  <span v-if="bObligatorio" class="font-weight-bold ml-1 red--text">*</span>
  <v-text-field
    v-model="valor"
    v-bind="oAtributos"
    hide-details
    :rules="emailValidacion"
    single-line
    outlined
    clearable
    dense
    :error="esError"
  ></v-text-field>
  <!-- Alinear el botón a la derecha con estilo en línea -->
  <div style="display: flex; justify-content: flex-end;">
    <v-btn
      class="mx-2 my-2"
      fab
      dark
      color="#63A9E7"
      @click="onValidarFormatoCorreo"
      small
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
  </div>

<div>
  <v-card
    v-for="sCorreoTemp in aListaCorreos"
    :key="sCorreoTemp"
    elevation="2"
    class="px-2 d-flex my-3"
  >
    <span class="pt-2">{{ sCorreoTemp }}</span>

    <!-- Botón alineado completamente a la derecha -->
    <v-btn icon color="red" class="ml-auto" @click="onEliminarCorreo(sCorreoTemp)">
      <v-icon>mdi-delete</v-icon>
    </v-btn>
  </v-card>
</div>



</div>
</template>

<script>
const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

export default {
  name: 'FormCorreo',
  props: {
    oAtributos:{
      type:Object,
      default:()=>{}
    },
    sLabel:{
      type: String,
      default: "",
    },
    bObligatorio:{
        type: Boolean,
        default: false,
    },
  },
  data() {
    return {
      valor   : this.value || '',
      emailValidacion : [
        v => !!v ,
        v => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(v),
      ],
      esError : false,
      nMaxCorreos : 0,    // Numero maximo de correos si es 0 no hay limites
      aListaCorreos:[]
    }
  },
  mounted(){
  },
  methods: {
    onValidarFormatoCorreo(){
      if(this.valor === null) return
      if(this.valor.length > 0 && emailRegex.test(this.valor)){
        this.aListaCorreos.push(this.valor);
        this.valor="";
      }
    },
    onEliminarCorreo(sCorreoTemp){
      this.aListaCorreos = this.aListaCorreos.filter(item => item !== sCorreoTemp);
    }
  },
  computed: {
  },
  watch: {
    aListaCorreos(){
      this.$emit('input', JSON.stringify(this.aListaCorreos));
    }
  },
    
}
</script>

<style scoped>


</style>