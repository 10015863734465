<template>
    <div>
        <v-row justify="center">
            <v-dialog
                v-model="value"
                persistent
                max-width="490"
            >
                <v-card>
                    <v-card-title class="text-subtitle-2">
                        Campos obligatorios sin diligenciar
                    </v-card-title>
                    <v-card-text>
                        <v-alert class="text-caption font-weight-medium" color="#ebedf0" dense>
                            <span v-for="sError in aListaErrores" class="red--text">
                                * Campo {{ sError }} sin diligenciar <br>
                            </span>
                        </v-alert>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="#63A9E7"
                            text
                            @click="onCerrarModal"
                        >
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
export default {
    name: 'ModalError',
    props: {
        value: Boolean, // v-model support
        aListaErrores:{
            type: Array,
            default: () => []
        },
    },
    methods:{
        onCerrarModal(){
            this.$emit('input', false);
        }
    }
}
</script>