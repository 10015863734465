<template>
    <ModuloItem :dataitem="getInfo()"/>
</template>

<script>
import ValidarBD from "@/utils//ValidarBD.js"
import ModuloItem from "@/components/ModuloItem.vue"
import ModulosItems from "@/components/ModulosItems.js"
import { mapGetters } from 'vuex';

export default {
  name: "Home",
  components: {
    ModuloItem
  },
  data() {
    return {
    }
  },
  mounted () {
    this.$store.dispatch('setbNavegacion', false)
    // valido si no requiere actualizar BD
    if( ValidarBD.getRequiereActualizarDB() ){
      this.$router.replace({name: 'DescargaDB'}) 
    }
  },
  methods: {
    getInfo() {
      let infoItem = []
      console.log( this.validarPermisos(`${this.itemshome[0].Nombre_app}`))
      this.itemshome.forEach(element => {
        if((element.Titulo != 'Sin Asignar') && (this.validarPermisos(`${element.Nombre_app}`))){
          infoItem.push(element)
        }
      });
      return infoItem
    },
    validarPermisos(nameApp) {
      if(nameApp == "ActivityReporteNovedadIncidente") return true
      let encontrar = this.getaPermisos.find( item => item.Nombre_app == nameApp)
      return encontrar ? true : false 
    }
  },
  computed: {
    itemshome() {
      return ModulosItems.ModulosApp
    },
    ...mapGetters(['getaPermisos'])
  },

};
</script>