<template>
  <v-card
    outlined
    width="100%"
    :class="oRonda.estado === 'Bloqueado' ? 'grey lighten-2' : 'mx-auto card-rounded over-hidden'"
  >
    <v-row class="pa-3"> 
      <v-col cols="10" sm="10" md="10">
        <v-card-title class="pa-1 my-0 text-size-2">
          {{ oRonda.nombre }}
        </v-card-title>
        <v-row no-gutters>
         <v-col no-gutters>
           <v-card-subtitle class="pa-1 my-1 text-size-1">Puntos de marcación: {{ oRonda.puntos }}</v-card-subtitle>
           <v-card-subtitle class="pa-1 my-1 text-size-1">Hora de inicio: {{ oRonda.hora }}</v-card-subtitle>
           <v-card-subtitle class="pa-1 my-1 text-size-1">
             Estado: <v-chip label :color="onGetColorEstado(oRonda)" class="white--text" >{{ oRonda.estado }}</v-chip>
           </v-card-subtitle>
         </v-col>
       </v-row>
      </v-col>

      <v-col cols="2" sm="2" md="1" class="d-flex justify-center align-center text-xs-center">
        <v-btn large icon @click="onClickRonda(oRonda)">
          <v-icon color="#003743">mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>

    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'RondaItemCard',
  props: {
    oRonda: {
      type: Object,
      default:()=> {}
    }
  },
  data() {
    return {
    };
  },
  computed: {

  },
  mounted() {
    
  },
  methods: {
    onGetColorEstado(oRonda){
      switch (oRonda.estado) {
        case 'Sin iniciar':
          return 'red accent-2'
        case 'En progreso':
          return 'primary'
        case 'Completada':
          return '#019578'
        case 'Incompleta':
          return '#F9A825'
        default:
          return 'grey darken-1'
      }
    },
    onClickRonda(oRonda){
      this.$emit('onClickRonda', oRonda)
    }
  },
};
</script>

<style scoped>

.v-list-item-content {
  font-size: 14px;
}


</style>
