/* eslint-disable no-console */

// En este archivo gurado toda la configuracion del firebase para luego importalo en el main.js.

import Vue from "vue"

// manejo de firebase 
import firebase from "firebase/app"
import "firebase/analytics"
import "firebase/performance"
import "firebase/messaging"

// firebase metricas -- la configuracion debe ser cambiada segun el proyecto
const firebaseConfig = {
  apiKey: "AIzaSyCCTJmVdZ1Bh67fsoHOfsyBmlU8iBIaJtM",
  authDomain: "seif-modulos.firebaseapp.com",
  databaseURL: "https://seif-modulos.firebaseio.com",
  projectId: "seif-modulos",
  storageBucket: "seif-modulos.appspot.com",
  messagingSenderId: "315828580733",
  appId: "1:315828580733:web:62a3b3934d35313fcd9c14",
  measurementId: "G-503DPG1XL1"
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.performance()
Vue.use(firebase)
Vue.prototype.$firebase = firebase