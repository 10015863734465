<template>
    <v-layout row wrap justify-center align-center pt-2 class="home-container container">
      <v-flex xs12 sm4 md4 lg4 mt-1 v-for="(item,index) in dataitem" :key="'itemhome'+index" class="item-container" row wrap>
        <v-flex xs2 sm12 md12>
          <div class="item-icon"><v-icon>{{item.Icon}}</v-icon></div>
        </v-flex>
        <v-flex xs10 sm12 md12 class="cont-text">
          <div class="item-tittle">{{item.Titulo}}</div>
          <div class="item-button" @click="GoTo(item.Link)">{{item.Descripcion}}</div>
        </v-flex>
      </v-flex>
    </v-layout>
</template>
<script>
export default {
  name:"ModuloItem",
  props: ['dataitem'],
  data() {
    return {
    }
  },
  methods: {
    GoTo(link) {
    this.$router.replace(link)      
    }
  },

}
</script>

<style lang="scss">
.home-container{
  width: 100%;
  padding: 0;
  padding-bottom: 80px;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}
.item-container{
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  padding: 10px;
  .item-icon{
    background: linear-gradient(0deg, rgba(218,218,218,1) 0%, rgba(238,238,238,1) 100%);
    width: 50px;
    height: 50px;
    border: solid #FFF 1px;
    box-shadow: 0 0 10px 3px rgba($color: #000000, $alpha: .2);
    margin: 0 auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    i{
    font-size: 30px;
    color:var(--v-primary-base);
    }
    @media screen and(min-width: 600px) {
      width: 70px;
      height: 70px;
      i{
        font-size: 42px;
      }
    }
  }
  .cont-text{
    padding-left: 10px;
    text-align: left;
    align-content:left;
    .item-tittle{
      font-weight: 500;
      margin-bottom: 5px;
    }
    .item-button{
      width: auto;
      padding: 10px;
      border-radius: 4px;
      background-color:var(--v-primary-base);
      color: #FFF;
      line-height: 1.3em;
      font-size: 0.8em;
      text-transform: uppercase;
      font-weight: bolder;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
