<template>
  <!-- Componente tipo modal para ampliar fotos y texto 
  Tiene dos props para cargar los textos e imagen
  Se debe declarar para su uso el con model el v-model
  -->
  <v-dialog v-model="modelShow" persistent :overlay="false" max-width="500px" >
      <v-card>
      <v-toolbar dark color="primary" dense>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('click', false)"><v-icon>mdi-window-close</v-icon></v-btn>
      </v-toolbar>
      <div class="py-3 px-3">
          <img :src="fotoampliada" width="100%">
      </div>
      <div class="py-2 px-2">
        <span>{{textoampliado}}</span>
      </div>
      </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name:'AmpliarFoto',
    model:{
      prop: 'modelShow',
      event: 'click'
    },
    props: {
      modelShow: Boolean,
      fotoampliada :{
        default:null
      },
      textoampliado:{
        default:null
      }
    },
    data() {
      return {
      }
    },
    methods: {
    },
    
  }
</script>

<style lang="scss" scoped>
// corrige un margin negativo que está declarado en algún lugar del universo de vuetify 
.row {
  margin: 0!important;
}
.cont-resp-texto{
  font-size: 0.8em;
}
.under-sec{
  padding: 2px!important;
}
.btn-img{
  border-radius: 10px;
  img{
    width: 100%;
  }
}
</style>